/* eslint-disable */
import type { FC } from "react";

import {
  type UseTranslationOptions,
  type TFunctionGeneric,
  useTranslationGeneric,
  type WithTranslationProps as WithTranslationPropsGeneric,
  withTranslationGeneric,
  type TransProps as TransPropsGeneric,
  makeTransComponent,
  standaloneTestT as standaloneTestTInternal,
  type StandaloneTestTOptions,
  getCustomStandaloneTestT as getCustomStandaloneTestTInternal,
  type FrontendLanguages as Languages,
} from "@equiem/localisation"

import enApplication from "./../../../localisation/src/locales/en/equiem-one-ui.json";
import enServer from "./../../../localisation/src/locales/en/server-messages.json";

/** @public */
export type ServerMessages = {
  "activity-api.errors.commentRequired": undefined;
  "activity-api.errors.createChangelogFailed": undefined;
  "activity-api.errors.createCommentFailed": undefined;
  "activity-api.errors.noAccessToDoThisOperation": undefined;
  "activity-api.errors.userNotFound": undefined;
  "admin-api.errors.apartment.alreadyExists": { name: string | number };
  "admin-api.errors.barrierAccessControl.alreadyExists": undefined;
  "admin-api.errors.barrierAccessControl.notFound": undefined;
  "admin-api.errors.barrierAccessControl.notFoundAccess": undefined;
  "admin-api.errors.building.buildingNameCannotBeEmpty": undefined;
  "admin-api.errors.building.buildingNameExistsWithinTheSiteAlready": undefined;
  "admin-api.errors.building.buildingsMustBeInsideTheSameDestination": undefined;
  "admin-api.errors.building.buildingsNotFound": undefined;
  "admin-api.errors.building.destinationUuidCannotBeEmpty": undefined;
  "admin-api.errors.building.failedToDuplicateBuilding": undefined;
  "admin-api.errors.building.failedToUpdateBuildingIntegrations": undefined;
  "admin-api.errors.building.failedToUpdateBuildings": undefined;
  "admin-api.errors.building.taxRateLabelCannotBeEmpty": undefined;
  "admin-api.errors.building.taxRateLabelMustBeUnique": undefined;
  "admin-api.errors.building.taxRatesCannotBeDeleted": undefined;
  "admin-api.errors.building.taxRatesCannotBeNegative": undefined;
  "admin-api.errors.buildingLevel.buildingNotFound": undefined;
  "admin-api.errors.buildingLevel.failedToCreate": undefined;
  "admin-api.errors.buildingLevel.failedToDelete": undefined;
  "admin-api.errors.buildingLevel.failedToDuplicate": undefined;
  "admin-api.errors.buildingLevel.failedToUpdate": undefined;
  "admin-api.errors.buildingLevel.failedToUpdateOrder": undefined;
  "admin-api.errors.buildingLevel.mustBeInsideTheSameBuilding": undefined;
  "admin-api.errors.buildingLevel.nameCannotBeEmpty": undefined;
  "admin-api.errors.buildingLevel.nameExistsOnBuilding": { name: string | number, buildingName: string | number };
  "admin-api.errors.buildingLevel.notFound": undefined;
  "admin-api.errors.buildingLevel.notFoundMultiple": undefined;
  "admin-api.errors.common.accessDenied": undefined;
  "admin-api.errors.common.regionNotProvided": undefined;
  "admin-api.errors.common.unknownError": undefined;
  "admin-api.errors.common.userNoPermissionDestination": undefined;
  "admin-api.errors.common.userNoPermissionDestinationOrEquiemOne": undefined;
  "admin-api.errors.common.userNotAuthenticated": undefined;
  "admin-api.errors.common.userNotAuthorisedActingAs": { uuid: string | number, userUuid: string | number };
  "admin-api.errors.common.userNotAuthorisedAddLevels": undefined;
  "admin-api.errors.common.userNotAuthorisedSite": undefined;
  "admin-api.errors.company.notFound": undefined;
  "admin-api.errors.flex.membershipLimitReached": undefined;
  "admin-api.errors.flex.noFlexOperatorFound": undefined;
  "admin-api.errors.flex.noFlexTenantsFound": undefined;
  "admin-api.errors.invites.missingCompany": { emails: string | number, count: number };
  "admin-api.errors.invites.unknownRole": undefined;
  "admin-api.errors.space.buildingLevelNotFound": undefined;
  "admin-api.errors.space.buildingLevelNotFoundMultiple": undefined;
  "admin-api.errors.space.failedToBulkCreate": undefined;
  "admin-api.errors.space.failedToCreate": undefined;
  "admin-api.errors.space.failedToDelete": undefined;
  "admin-api.errors.space.failedToDuplicate": undefined;
  "admin-api.errors.space.failedToUpdate": undefined;
  "admin-api.errors.space.failedToUpdateOrder": undefined;
  "admin-api.errors.space.mustBeInsideTheSameLevel": undefined;
  "admin-api.errors.space.nameCannotBeEmpty": undefined;
  "admin-api.errors.space.nameExistsOnLevel": { name: string | number, levelName: string | number };
  "admin-api.errors.space.notFound": undefined;
  "admin-api.errors.space.notFoundMultiple": undefined;
  "admin-api.errors.statsProvider.urlNotProvided": undefined;
  "admin-api.errors.whitelabel.mustProvideClientUUIDAndName": undefined;
  "bookings-api.bookings.errors.addOnNotFound": undefined;
  "bookings-api.bookings.errors.additionalChargeUnsuccessful": { currency: string, price: number, reference: string | number };
  "bookings-api.bookings.errors.adjustmentNotFound": undefined;
  "bookings-api.bookings.errors.amountMustBeGreater": undefined;
  "bookings-api.bookings.errors.automaticApprovalIsRequired": undefined;
  "bookings-api.bookings.errors.availabilitiesDaysFieldError": undefined;
  "bookings-api.bookings.errors.bookingHasNoAccessCode": undefined;
  "bookings-api.bookings.errors.businessHoursMustBeValid": undefined;
  "bookings-api.bookings.errors.cancellationPeriodMustBeGreater": undefined;
  "bookings-api.bookings.errors.cancellationRatesCannotExceed": undefined;
  "bookings-api.bookings.errors.cannotScheduleResourceBeforeActiveFinishes": undefined;
  "bookings-api.bookings.errors.capacityMustBeGreater": undefined;
  "bookings-api.bookings.errors.chargeForBookingNotProcessed": undefined;
  "bookings-api.bookings.errors.childResourcesHaveChildren": { childrenWithChildren: string | number };
  "bookings-api.bookings.errors.childResourcesNotFound": { missingChildren: string | number };
  "bookings-api.bookings.errors.companyDataNotFound": undefined;
  "bookings-api.bookings.errors.companyIsNotFlexOperator": undefined;
  "bookings-api.bookings.errors.deletionTimeCannotBeInPast": undefined;
  "bookings-api.bookings.errors.didNotReceiveResponse": undefined;
  "bookings-api.bookings.errors.durationMinimumIs": { index: string | number };
  "bookings-api.bookings.errors.endDateCantBeBeforeStart": undefined;
  "bookings-api.bookings.errors.exceedMaximumTimeAllowed": { count: string | number };
  "bookings-api.bookings.errors.failedCreateCustomer": undefined;
  "bookings-api.bookings.errors.failedCreateIntent": undefined;
  "bookings-api.bookings.errors.failedLoadUserInfo": undefined;
  "bookings-api.bookings.errors.failedLoadYourProfileInfo": undefined;
  "bookings-api.bookings.errors.failedToRefund": undefined;
  "bookings-api.bookings.errors.halfDayDurationMustBeGreater": undefined;
  "bookings-api.bookings.errors.invalidAccessGroup": undefined;
  "bookings-api.bookings.errors.invalidAddOnAmountRefund": { name: string | number, amount: string | number };
  "bookings-api.bookings.errors.invalidAmountOfAdjustment": { amount: string | number };
  "bookings-api.bookings.errors.invalidAmountPartialRefund": { currency: string, price: number, item: string | number };
  "bookings-api.bookings.errors.invalidAvailabilityDateRange": undefined;
  "bookings-api.bookings.errors.invalidBookingAmountRefund": { amount: string | number };
  "bookings-api.bookings.errors.invalidBookingDiscountPercentage": undefined;
  "bookings-api.bookings.errors.invalidDuration": undefined;
  "bookings-api.bookings.errors.invalidEarlyBookingDiscountNumberOfDays": undefined;
  "bookings-api.bookings.errors.invalidEarlyBookingInput": undefined;
  "bookings-api.bookings.errors.invalidEndFormatOnAvailability": { index: string | number };
  "bookings-api.bookings.errors.invalidResourceStatus": undefined;
  "bookings-api.bookings.errors.invalidStartFormatOnAvailability": { index: string | number };
  "bookings-api.bookings.errors.invalidTimePeriod": undefined;
  "bookings-api.bookings.errors.maximumTimeBeforeStart": undefined;
  "bookings-api.bookings.errors.minimumBookingTimeMustBeLess": { index: string | number };
  "bookings-api.bookings.errors.minimumTimeBeforeStart": undefined;
  "bookings-api.bookings.errors.minimumTimeBeforeStartMustBeLess": undefined;
  "bookings-api.bookings.errors.missingCurrencyInformation": undefined;
  "bookings-api.bookings.errors.missingSiteInformation": undefined;
  "bookings-api.bookings.errors.nameIsRequired": undefined;
  "bookings-api.bookings.errors.noDestinationSpecified": undefined;
  "bookings-api.bookings.errors.noManuallyApprovedCreditBookings": undefined;
  "bookings-api.bookings.errors.noManuallyApprovedCreditCardBookings": undefined;
  "bookings-api.bookings.errors.noPermissionToCreateResources": undefined;
  "bookings-api.bookings.errors.noPermissionToInformation": undefined;
  "bookings-api.bookings.errors.nothingToCharge": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentDisabled": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentMaxDateError": { max: string | number };
  "bookings-api.bookings.errors.paymentAdjustmentNotApplicableForCancelledBooking": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentNotApplicableForFreeBooking": undefined;
  "bookings-api.bookings.errors.paymentRatesMustBeGreater": undefined;
  "bookings-api.bookings.errors.paymentRejected": { responseCode: string | number };
  "bookings-api.bookings.errors.paymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "bookings-api.bookings.errors.permissionDenied": undefined;
  "bookings-api.bookings.errors.pleaseProvideCreditInformation": undefined;
  "bookings-api.bookings.errors.pleaseSetStartDate": undefined;
  "bookings-api.bookings.errors.preparationTimeAfterMustBeGreater": undefined;
  "bookings-api.bookings.errors.preparationTimeBeforeMustBeGreater": undefined;
  "bookings-api.bookings.errors.provideOneAvailability": undefined;
  "bookings-api.bookings.errors.provideSlotsDuration": undefined;
  "bookings-api.bookings.errors.reasonIsRequired": undefined;
  "bookings-api.bookings.errors.refundFailed": undefined;
  "bookings-api.bookings.errors.refundFailedMessage": { refundMessage: string | number };
  "bookings-api.bookings.errors.resourceAlreadyDeleted": undefined;
  "bookings-api.bookings.errors.resourceAlreadyExist": undefined;
  "bookings-api.bookings.errors.resourceCannotBeOwnChild": undefined;
  "bookings-api.bookings.errors.resourceNoPublished": undefined;
  "bookings-api.bookings.errors.resourceNotFound": undefined;
  "bookings-api.bookings.errors.resourceNotPendingDeletion": undefined;
  "bookings-api.bookings.errors.resourceTypeIsRequired": undefined;
  "bookings-api.bookings.errors.resourceTypeNotFound": undefined;
  "bookings-api.bookings.errors.resourceTypeOldError": undefined;
  "bookings-api.bookings.errors.resourceWithNameExists": undefined;
  "bookings-api.bookings.errors.selectEndTime": undefined;
  "bookings-api.bookings.errors.selectStartTime": undefined;
  "bookings-api.bookings.errors.specifyAtLeastPneWeekday": { index: string | number };
  "bookings-api.bookings.errors.startTimeDateMismatch": undefined;
  "bookings-api.bookings.errors.startTimeMustBeBefore": { index: string | number };
  "bookings-api.bookings.errors.unableAllocateCurrencyCode": undefined;
  "bookings-api.bookings.errors.unableAllocateRegionId": undefined;
  "bookings-api.bookings.errors.unableFindCreditAccountInfo": undefined;
  "bookings-api.bookings.errors.unableFindCreditCardInfo": undefined;
  "bookings-api.bookings.errors.unableFindSiteInformation": undefined;
  "bookings-api.bookings.errors.unableFindUserDestination": undefined;
  "bookings-api.bookings.errors.unableFindYourSiteInformation": undefined;
  "bookings-api.bookings.errors.unableIdentifyPaymentGateway": undefined;
  "bookings-api.bookings.errors.unableRefundBookingNotPaid": undefined;
  "bookings-api.bookings.errors.unableToChangeResourceCompany": undefined;
  "bookings-api.bookings.errors.unableToFindChargeInfo": undefined;
  "bookings-api.bookings.errors.unableToLoadBookingSetting": undefined;
  "bookings-api.bookings.errors.unableToLoadClientInfo": undefined;
  "bookings-api.bookings.errors.vendorAccountNotConfigured": undefined;
  "bookings-api.bookings.errors.youCantApplyDifferentRates": undefined;
  "bookings-api.bookings.errors.youCantCancelRates": undefined;
  "bookings-api.bookings.errors.youCantShareResourceOutside": undefined;
  "bookings-api.bookings.errors.youMustConfigureBaseRates": undefined;
  "bookings-api.bookings.errors.youMustProvideRecipientDetails": undefined;
  "bookings-api.bookings.errors.youMustSelectInvoiceRecipient": undefined;
  "bookings-api.bookings.errors.youMustSelectPaymentMethods": undefined;
  "bookings-api.bookings.errors.youRefundFailed": undefined;
  "bookings-api.bookings.errors.youRefundFailedMessage": { refundMessage: string | number };
  "bookings-api.bookings.errors.yourPaymentRejected": { responseCode: string | number };
  "bookings-api.bookings.errors.yourPaymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "bookings-api.bookings.messages.SharedFacilitiesWithSameName": undefined;
  "bookings-api.bookings.messages.bookingAlreadyCancelled": undefined;
  "bookings-api.bookings.messages.bookingBlocked": undefined;
  "bookings-api.bookings.messages.bookingCanOnlyPaidByInvoice": undefined;
  "bookings-api.bookings.messages.bookingCannotBeCancelled": undefined;
  "bookings-api.bookings.messages.bookingCannotBeEditedRefundIssued": undefined;
  "bookings-api.bookings.messages.bookingCantBeChanged": undefined;
  "bookings-api.bookings.messages.bookingCantBrMultipleDays": undefined;
  "bookings-api.bookings.messages.bookingConflict": undefined;
  "bookings-api.bookings.messages.bookingContainsInvalidAddOns": undefined;
  "bookings-api.bookings.messages.bookingContainsInvalidAddOnsQuantities": undefined;
  "bookings-api.bookings.messages.bookingContainsMultipleSelections": undefined;
  "bookings-api.bookings.messages.bookingDeclined": undefined;
  "bookings-api.bookings.messages.bookingIsInThePast": undefined;
  "bookings-api.bookings.messages.bookingMissingMandatoryAddOns": undefined;
  "bookings-api.bookings.messages.bookingNotFound": undefined;
  "bookings-api.bookings.messages.bookingNotRequirePayment": undefined;
  "bookings-api.bookings.messages.bookingsContainsDuplicate": undefined;
  "bookings-api.bookings.messages.declinedReasonRequired": undefined;
  "bookings-api.bookings.messages.endTimeBeforeResourceDeleted": undefined;
  "bookings-api.bookings.messages.invalidRefundOrAdjustment": undefined;
  "bookings-api.bookings.messages.invoiceMethodCannotUpdated": undefined;
  "bookings-api.bookings.messages.manualApprovalCreditCard": undefined;
  "bookings-api.bookings.messages.maximumAddOnExceeded": undefined;
  "bookings-api.bookings.messages.maximumBookingIs": { time: number };
  "bookings-api.bookings.messages.minimumBookingIs": { time: number };
  "bookings-api.bookings.messages.mustSelectPaymentMethod": undefined;
  "bookings-api.bookings.messages.noAbleToLookResource": undefined;
  "bookings-api.bookings.messages.noNegativePrice": undefined;
  "bookings-api.bookings.messages.noPermissionsForAnotherUser": undefined;
  "bookings-api.bookings.messages.noPermissionsForCreditAccount": undefined;
  "bookings-api.bookings.messages.noPermissionsToBook": undefined;
  "bookings-api.bookings.messages.noPermissionsToChange": undefined;
  "bookings-api.bookings.messages.notPendingApproval": undefined;
  "bookings-api.bookings.messages.paymentMethodCannotUpdated": undefined;
  "bookings-api.bookings.messages.pleaseAcceptTermsAndConditions": undefined;
  "bookings-api.bookings.messages.pleaseContactPropertyManager": undefined;
  "bookings-api.bookings.messages.pleaseProvideBillingCustomerInfo": undefined;
  "bookings-api.bookings.messages.pleaseProvideCardInfo": undefined;
  "bookings-api.bookings.messages.pleaseSelectCreditAccount": undefined;
  "bookings-api.bookings.messages.recipientNotFound": undefined;
  "bookings-api.bookings.messages.resourceFeatureIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceFeatureNotEditable": undefined;
  "bookings-api.bookings.messages.resourceFeatureUnableToDelete": undefined;
  "bookings-api.bookings.messages.resourceFeatureWithSameName": undefined;
  "bookings-api.bookings.messages.resourceNameCantBeBookedEarly": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceNameCantBeBookedFar": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceNotAvailable": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceTypeIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceTypeNotEditable": undefined;
  "bookings-api.bookings.messages.resourceTypeUnableToDelete": undefined;
  "bookings-api.bookings.messages.resourceTypeWithSameName": undefined;
  "bookings-api.bookings.messages.resourceUsingResourceType": { resourceName: string | number, count: number };
  "bookings-api.bookings.messages.roomConfigurationIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.roomConfigurationNotEditable": undefined;
  "bookings-api.bookings.messages.roomConfigurationNotExist": undefined;
  "bookings-api.bookings.messages.roomConfigurationNotExistTryAgain": undefined;
  "bookings-api.bookings.messages.roomConfigurationUnableToDelete": undefined;
  "bookings-api.bookings.messages.roomConfigurationWithSameName": undefined;
  "bookings-api.bookings.messages.sharedFacilitiesIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.sharedFacilitiesNotEditable": undefined;
  "bookings-api.bookings.messages.sharedFacilitiesUnableToDelete": undefined;
  "bookings-api.bookings.messages.startTimeBeforeEndTime": undefined;
  "bookings-api.bookings.messages.thisBookingCannotBeChanged": undefined;
  "bookings-api.bookings.messages.unableFindAvailability": { resourceName: string | number };
  "bookings-api.bookings.messages.unableFindCompanyInformation": undefined;
  "bookings-api.bookings.messages.unableFindSiteInformation": undefined;
  "bookings-api.bookings.messages.unableToFindClientInformation": undefined;
  "bookings-api.bookings.messages.unableToFindResourceFeature": undefined;
  "bookings-api.bookings.messages.unableToFindResourceSharedFacilities": undefined;
  "bookings-api.bookings.messages.unableToFindResourceType": undefined;
  "bookings-api.bookings.messages.unableToFindRoomConfiguration": undefined;
  "bookings-api.bookings.messages.unableToFindSharedFacilities": undefined;
  "bookings-api.bookings.messages.unableToFindUser": undefined;
  "bookings-api.bookings.messages.unableToProcessPayment": undefined;
  "bookings-api.bookings.messages.unableToProcessRefund": undefined;
  "bookings-api.bookings.messages.userNotAPropertyManager": undefined;
  "bookings-api.bookings.messages.youCantUseSelectedMethod": undefined;
  "bookings-api.bookings.messages.youDoNotHavePermissionMakeBooking": undefined;
  "bookings-api.bookings.messages.youMustAcceptCancellationTerms": undefined;
  "bookings-api.bookings.messages.youMustProvideDetailsOfInvoice": undefined;
  "bookings-api.bookings.messages.youMustSelectRecipient": undefined;
  "bookings-api.common.errors.noDestinationSpecified": undefined;
  "bookings-api.siteCompany.errors.cannotModifySiteCompanyMaxAppointmentCreationMonths": undefined;
  "bookings-api.siteCompany.errors.siteCompanyMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "bookings-api.visitorManagementSiteSettings.errors.siteMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "bookings-api.visitors.errors.accessDeniedCompanyList": undefined;
  "bookings-api.visitors.errors.accessDeniedForReception": undefined;
  "bookings-api.visitors.errors.accessDeniedHostSuggestions": undefined;
  "bookings-api.visitors.errors.accessDeniedHostSuggestionsForCompany": undefined;
  "bookings-api.visitors.errors.appointmentDateExceedsAllowedBoundary": { date: string | number };
  "bookings-api.visitors.errors.createDeniedForReception": undefined;
  "bookings-api.visitors.errors.deleteActiveAppointment": undefined;
  "bookings-api.visitors.errors.editExpiredAppointment": undefined;
  "bookings-api.visitors.errors.emptyCompany": undefined;
  "bookings-api.visitors.errors.failedToFindBarrierConfig": { uuid: string | number };
  "bookings-api.visitors.errors.hostAndCompanyNotProvided": undefined;
  "bookings-api.visitors.errors.hostCompanyIsNull": undefined;
  "bookings-api.visitors.errors.hostNotFound": undefined;
  "bookings-api.visitors.errors.incorrectCompany": undefined;
  "bookings-api.visitors.errors.invalidAppointmentCompany": undefined;
  "bookings-api.visitors.errors.invalidAppointmentOwner": undefined;
  "bookings-api.visitors.errors.invalidAppointmentSite": undefined;
  "bookings-api.visitors.errors.invalidLocationForReception": undefined;
  "bookings-api.visitors.errors.invalidReceptionCompany": undefined;
  "bookings-api.visitors.errors.invalidReceptionCurrentCompany": undefined;
  "bookings-api.visitors.errors.invalidReceptionSite": undefined;
  "bookings-api.visitors.errors.notPropertyManager": undefined;
  "bookings-api.visitors.errors.notWorkplaceManager": undefined;
  "bookings-api.visitors.errors.receptionNotExistsOnSite": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToDetermineRecurringUpdateType": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToFindRecurringSettings": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentRecurringTypes": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorIsWalkIn": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorMissingProperty": { paramNames: string | number };
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorNotRecurring": undefined;
  "bookings-api.visitors.errors.statusChangeDenied": undefined;
  "integrations-api.errors.common.forbidden": { contactEmail: string | number };
  "integrations-api.errors.common.incompleteUserContext": undefined;
  "integrations-api.errors.gallagher.mobileCredentialCreateFailed": { email: string | number, contactEmail: string | number };
  "integrations-api.errors.gallagher.requestFailed": { contactEmail: string | number };
  "integrations-api.errors.gallagher.siteNotFound": undefined;
  "integrations-api.errors.gallagher.unavailable": { contactEmail: string | number };
  "integrations-api.errors.gallagher.userNotFound": { email: string | number, contactEmail: string | number };
  "integrations-api.errors.openpath.apiError": { statusText: string | number, status: string | number, contactEmail: string | number };
  "integrations-api.errors.openpath.incompleteUserContext": undefined;
  "integrations-api.errors.openpath.mobileCredentialAllocateFailed": { email: string | number };
  "integrations-api.errors.openpath.mobileCredentialCreateFailed": { email: string | number };
  "integrations-api.errors.openpath.mobileCredentialTypeNotFound": undefined;
  "integrations-api.errors.openpath.setupMobileTokenGenerateFailed": { email: string | number };
  "integrations-api.errors.openpath.siteNotFound": undefined;
  "integrations-api.errors.openpath.unavailable": { contactEmail: string | number };
  "integrations-api.errors.openpath.userEmailNotFound": undefined;
  "integrations-api.errors.openpath.userIncomplete": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.openpath.userNotFound": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.openpath.userOrgIdNotFound": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.swiftconnect.apiError": { statusText: string | number, status: string | number, contactEmail: string | number };
  "integrations-api.errors.swiftconnect.pendingAccess": { contactEmail: string | number };
  "integrations-api.errors.swiftconnect.siteNotFound": undefined;
  "integrations-api.errors.swiftconnect.unavailable": { contactEmail: string | number };
  "integrations-api.errors.swiftconnect.userEmailNotFound": undefined;
  "operations-api.bookings.errors.addOnNotFound": undefined;
  "operations-api.bookings.errors.additionalChargeUnsuccessful": { currency: string, price: number, reference: string | number };
  "operations-api.bookings.errors.adjustmentNotFound": undefined;
  "operations-api.bookings.errors.amountMustBeGreater": undefined;
  "operations-api.bookings.errors.businessHoursMustBeValid": undefined;
  "operations-api.bookings.errors.cancellationPeriodMustBeGreater": undefined;
  "operations-api.bookings.errors.cancellationRatesCannotExceed": undefined;
  "operations-api.bookings.errors.cannotScheduleResourceBeforeActiveFinishes": undefined;
  "operations-api.bookings.errors.capacityMustBeGreater": undefined;
  "operations-api.bookings.errors.chargeForBookingNotProcessed": undefined;
  "operations-api.bookings.errors.childResourcesHaveChildren": { childrenWithChildren: string | number };
  "operations-api.bookings.errors.childResourcesNotFound": { missingChildren: string | number };
  "operations-api.bookings.errors.companyDataNotFound": undefined;
  "operations-api.bookings.errors.companyIsNotFlexOperator": undefined;
  "operations-api.bookings.errors.deletionTimeCannotBeInPast": undefined;
  "operations-api.bookings.errors.didNotReceiveResponse": undefined;
  "operations-api.bookings.errors.durationMinimumIs": { index: string | number };
  "operations-api.bookings.errors.endDateCantBeBeforeStart": undefined;
  "operations-api.bookings.errors.failedCreateCustomer": undefined;
  "operations-api.bookings.errors.failedCreateIntent": undefined;
  "operations-api.bookings.errors.failedLoadUserInfo": undefined;
  "operations-api.bookings.errors.failedLoadYourProfileInfo": undefined;
  "operations-api.bookings.errors.failedToRefund": undefined;
  "operations-api.bookings.errors.halfDayDurationMustBeGreater": undefined;
  "operations-api.bookings.errors.invalidAddOnAmountRefund": { name: string | number, amount: string | number };
  "operations-api.bookings.errors.invalidAmountOfAdjustment": { amount: string | number };
  "operations-api.bookings.errors.invalidAmountPartialRefund": { currency: string, price: number, item: string | number };
  "operations-api.bookings.errors.invalidAvailabilityDateRange": undefined;
  "operations-api.bookings.errors.invalidBookingAmountRefund": { amount: string | number };
  "operations-api.bookings.errors.invalidDuration": undefined;
  "operations-api.bookings.errors.invalidEndFormatOnAvailability": { index: string | number };
  "operations-api.bookings.errors.invalidResourceStatus": undefined;
  "operations-api.bookings.errors.invalidStartFormatOnAvailability": { index: string | number };
  "operations-api.bookings.errors.invalidTimePeriod": undefined;
  "operations-api.bookings.errors.maximumTimeBeforeStart": undefined;
  "operations-api.bookings.errors.minimumBookingTimeMustBeLess": { index: string | number };
  "operations-api.bookings.errors.minimumTimeBeforeStart": undefined;
  "operations-api.bookings.errors.minimumTimeBeforeStartMustBeLess": undefined;
  "operations-api.bookings.errors.missingCurrencyInformation": undefined;
  "operations-api.bookings.errors.missingSiteInformation": undefined;
  "operations-api.bookings.errors.nameIsRequired": undefined;
  "operations-api.bookings.errors.noDestinationSpecified": undefined;
  "operations-api.bookings.errors.noManuallyApprovedCreditBookings": undefined;
  "operations-api.bookings.errors.noManuallyApprovedCreditCardBookings": undefined;
  "operations-api.bookings.errors.noPermissionToCreateResources": undefined;
  "operations-api.bookings.errors.noPermissionToInformation": undefined;
  "operations-api.bookings.errors.nothingToCharge": undefined;
  "operations-api.bookings.errors.paymentAdjustmentDisabled": undefined;
  "operations-api.bookings.errors.paymentAdjustmentMaxDateError": { max: string | number };
  "operations-api.bookings.errors.paymentAdjustmentNotApplicableForCancelledBooking": undefined;
  "operations-api.bookings.errors.paymentAdjustmentNotApplicableForFreeBooking": undefined;
  "operations-api.bookings.errors.paymentRatesMustBeGreater": undefined;
  "operations-api.bookings.errors.paymentRejected": { responseCode: string | number };
  "operations-api.bookings.errors.paymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "operations-api.bookings.errors.permissionDenied": undefined;
  "operations-api.bookings.errors.pleaseProvideCreditInformation": undefined;
  "operations-api.bookings.errors.pleaseSetStartDate": undefined;
  "operations-api.bookings.errors.preparationTimeAfterMustBeGreater": undefined;
  "operations-api.bookings.errors.preparationTimeBeforeMustBeGreater": undefined;
  "operations-api.bookings.errors.provideOneAvailability": undefined;
  "operations-api.bookings.errors.provideSlotsDuration": undefined;
  "operations-api.bookings.errors.reasonIsRequired": undefined;
  "operations-api.bookings.errors.refundFailed": undefined;
  "operations-api.bookings.errors.refundFailedMessage": { refundMessage: string | number };
  "operations-api.bookings.errors.resourceAlreadyDeleted": undefined;
  "operations-api.bookings.errors.resourceAlreadyExist": undefined;
  "operations-api.bookings.errors.resourceCannotBeOwnChild": undefined;
  "operations-api.bookings.errors.resourceNoPublished": undefined;
  "operations-api.bookings.errors.resourceNotFound": undefined;
  "operations-api.bookings.errors.resourceNotPendingDeletion": undefined;
  "operations-api.bookings.errors.resourceTypeIsRequired": undefined;
  "operations-api.bookings.errors.resourceTypeNotFound": undefined;
  "operations-api.bookings.errors.resourceTypeOldError": undefined;
  "operations-api.bookings.errors.resourceWithNameExists": undefined;
  "operations-api.bookings.errors.specifyAtLeastPneWeekday": { index: string | number };
  "operations-api.bookings.errors.startTimeDateMismatch": undefined;
  "operations-api.bookings.errors.startTimeMustBeBefore": { index: string | number };
  "operations-api.bookings.errors.unableAllocateCurrencyCode": undefined;
  "operations-api.bookings.errors.unableAllocateRegionId": undefined;
  "operations-api.bookings.errors.unableFindCreditAccountInfo": undefined;
  "operations-api.bookings.errors.unableFindCreditCardInfo": undefined;
  "operations-api.bookings.errors.unableFindSiteInformation": undefined;
  "operations-api.bookings.errors.unableFindUserDestination": undefined;
  "operations-api.bookings.errors.unableFindYourSiteInformation": undefined;
  "operations-api.bookings.errors.unableIdentifyPaymentGateway": undefined;
  "operations-api.bookings.errors.unableRefundBookingNotPaid": undefined;
  "operations-api.bookings.errors.unableToChangeResourceCompany": undefined;
  "operations-api.bookings.errors.unableToFindChargeInfo": undefined;
  "operations-api.bookings.errors.unableToLoadBookingSetting": undefined;
  "operations-api.bookings.errors.unableToLoadClientInfo": undefined;
  "operations-api.bookings.errors.vendorAccountNotConfigured": undefined;
  "operations-api.bookings.errors.youCantApplyDifferentRates": undefined;
  "operations-api.bookings.errors.youCantCancelRates": undefined;
  "operations-api.bookings.errors.youCantShareResourceOutside": undefined;
  "operations-api.bookings.errors.youMustConfigureBaseRates": undefined;
  "operations-api.bookings.errors.youMustProvideRecipientDetails": undefined;
  "operations-api.bookings.errors.youMustSelectInvoiceRecipient": undefined;
  "operations-api.bookings.errors.youMustSelectPaymentMethods": undefined;
  "operations-api.bookings.errors.youRefundFailed": undefined;
  "operations-api.bookings.errors.youRefundFailedMessage": { refundMessage: string | number };
  "operations-api.bookings.errors.yourPaymentRejected": { responseCode: string | number };
  "operations-api.bookings.errors.yourPaymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "operations-api.bookings.messages.SharedFacilitiesWithSameName": undefined;
  "operations-api.bookings.messages.bookingAlreadyCancelled": undefined;
  "operations-api.bookings.messages.bookingBlocked": undefined;
  "operations-api.bookings.messages.bookingCanOnlyPaidByInvoice": undefined;
  "operations-api.bookings.messages.bookingCannotBeCancelled": undefined;
  "operations-api.bookings.messages.bookingCannotBeEditedRefundIssued": undefined;
  "operations-api.bookings.messages.bookingCantBeChanged": undefined;
  "operations-api.bookings.messages.bookingCantBrMultipleDays": undefined;
  "operations-api.bookings.messages.bookingConflict": undefined;
  "operations-api.bookings.messages.bookingContainsInvalidAddOns": undefined;
  "operations-api.bookings.messages.bookingContainsInvalidAddOnsQuantities": undefined;
  "operations-api.bookings.messages.bookingContainsMultipleSelections": undefined;
  "operations-api.bookings.messages.bookingDeclined": undefined;
  "operations-api.bookings.messages.bookingIsInThePast": undefined;
  "operations-api.bookings.messages.bookingMissingMandatoryAddOns": undefined;
  "operations-api.bookings.messages.bookingNotFound": undefined;
  "operations-api.bookings.messages.bookingNotRequirePayment": undefined;
  "operations-api.bookings.messages.bookingsContainsDuplicate": undefined;
  "operations-api.bookings.messages.declinedReasonRequired": undefined;
  "operations-api.bookings.messages.endTimeBeforeResourceDeleted": undefined;
  "operations-api.bookings.messages.invoiceMethodCannotUpdated": undefined;
  "operations-api.bookings.messages.manualApprovalCreditCard": undefined;
  "operations-api.bookings.messages.maximumAddOnExceeded": undefined;
  "operations-api.bookings.messages.maximumBookingIs": { time: number };
  "operations-api.bookings.messages.minimumBookingIs": { time: number };
  "operations-api.bookings.messages.mustSelectPaymentMethod": undefined;
  "operations-api.bookings.messages.noAbleToLookResource": undefined;
  "operations-api.bookings.messages.noPermissionsForAnotherUser": undefined;
  "operations-api.bookings.messages.noPermissionsForCreditAccount": undefined;
  "operations-api.bookings.messages.noPermissionsToBook": undefined;
  "operations-api.bookings.messages.noPermissionsToChange": undefined;
  "operations-api.bookings.messages.notPendingApproval": undefined;
  "operations-api.bookings.messages.paymentMethodCannotUpdated": undefined;
  "operations-api.bookings.messages.pleaseAcceptTermsAndConditions": undefined;
  "operations-api.bookings.messages.pleaseContactPropertyManager": undefined;
  "operations-api.bookings.messages.pleaseProvideBillingCustomerInfo": undefined;
  "operations-api.bookings.messages.pleaseProvideCardInfo": undefined;
  "operations-api.bookings.messages.pleaseSelectCreditAccount": undefined;
  "operations-api.bookings.messages.recipientNotFound": undefined;
  "operations-api.bookings.messages.resourceFeatureIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.resourceFeatureNotEditable": undefined;
  "operations-api.bookings.messages.resourceFeatureUnableToDelete": undefined;
  "operations-api.bookings.messages.resourceFeatureWithSameName": undefined;
  "operations-api.bookings.messages.resourceNameCantBeBookedEarly": { resourceName: string | number };
  "operations-api.bookings.messages.resourceNameCantBeBookedFar": { resourceName: string | number };
  "operations-api.bookings.messages.resourceNotAvailable": { resourceName: string | number };
  "operations-api.bookings.messages.resourceTypeIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.resourceTypeNotEditable": undefined;
  "operations-api.bookings.messages.resourceTypeUnableToDelete": undefined;
  "operations-api.bookings.messages.resourceTypeWithSameName": undefined;
  "operations-api.bookings.messages.resourceUsingResourceType": { resourceName: string | number, count: number };
  "operations-api.bookings.messages.roomConfigurationIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.roomConfigurationNotEditable": undefined;
  "operations-api.bookings.messages.roomConfigurationNotExist": undefined;
  "operations-api.bookings.messages.roomConfigurationNotExistTryAgain": undefined;
  "operations-api.bookings.messages.roomConfigurationUnableToDelete": undefined;
  "operations-api.bookings.messages.roomConfigurationWithSameName": undefined;
  "operations-api.bookings.messages.sharedFacilitiesIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.sharedFacilitiesNotEditable": undefined;
  "operations-api.bookings.messages.sharedFacilitiesUnableToDelete": undefined;
  "operations-api.bookings.messages.startTimeBeforeEndTime": undefined;
  "operations-api.bookings.messages.thisBookingCannotBeChanged": undefined;
  "operations-api.bookings.messages.unableFindAvailability": { resourceName: string | number };
  "operations-api.bookings.messages.unableFindCompanyInformation": undefined;
  "operations-api.bookings.messages.unableFindSiteInformation": undefined;
  "operations-api.bookings.messages.unableToFindClientInformation": undefined;
  "operations-api.bookings.messages.unableToFindResourceFeature": undefined;
  "operations-api.bookings.messages.unableToFindResourceSharedFacilities": undefined;
  "operations-api.bookings.messages.unableToFindResourceType": undefined;
  "operations-api.bookings.messages.unableToFindRoomConfiguration": undefined;
  "operations-api.bookings.messages.unableToFindSharedFacilities": undefined;
  "operations-api.bookings.messages.unableToFindUser": undefined;
  "operations-api.bookings.messages.unableToProcessPayment": undefined;
  "operations-api.bookings.messages.unableToProcessRefund": undefined;
  "operations-api.bookings.messages.userNotAPropertyManager": undefined;
  "operations-api.bookings.messages.youCantUseSelectedMethod": undefined;
  "operations-api.bookings.messages.youDoNotHavePermissionMakeBooking": undefined;
  "operations-api.bookings.messages.youMustAcceptCancellationTerms": undefined;
  "operations-api.bookings.messages.youMustProvideDetailsOfInvoice": undefined;
  "operations-api.bookings.messages.youMustSelectRecipient": undefined;
  "operations-api.common.errors.noDestinationSpecified": undefined;
  "operations-api.siteCompany.errors.cannotModifySiteCompanyMaxAppointmentCreationMonths": undefined;
  "operations-api.siteCompany.errors.siteCompanyMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "operations-api.visitorManagementSiteSettings.errors.siteMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "operations-api.visitors.errors.accessDeniedCompanyList": undefined;
  "operations-api.visitors.errors.accessDeniedForReception": undefined;
  "operations-api.visitors.errors.accessDeniedHostSuggestions": undefined;
  "operations-api.visitors.errors.accessDeniedHostSuggestionsForCompany": undefined;
  "operations-api.visitors.errors.appointmentDateExceedsAllowedBoundary": { date: string | number };
  "operations-api.visitors.errors.createDeniedForReception": undefined;
  "operations-api.visitors.errors.createVisitorAppointmentForBookingBookingAlreadyExists": { bookingUuid: string | number, appointmentUuid: string | number };
  "operations-api.visitors.errors.deleteActiveAppointment": undefined;
  "operations-api.visitors.errors.editExpiredAppointment": undefined;
  "operations-api.visitors.errors.emptyCompany": undefined;
  "operations-api.visitors.errors.failedLoadBookingsProfileInfo": undefined;
  "operations-api.visitors.errors.failedToFindBarrierConfig": { uuid: string | number };
  "operations-api.visitors.errors.hostAndCompanyNotProvided": undefined;
  "operations-api.visitors.errors.hostCompanyIsNull": undefined;
  "operations-api.visitors.errors.hostNotFound": undefined;
  "operations-api.visitors.errors.incorrectCompany": undefined;
  "operations-api.visitors.errors.invalidAppointmentCompany": undefined;
  "operations-api.visitors.errors.invalidAppointmentOwner": undefined;
  "operations-api.visitors.errors.invalidAppointmentSite": undefined;
  "operations-api.visitors.errors.invalidLocationForReception": undefined;
  "operations-api.visitors.errors.invalidReceptionCompany": undefined;
  "operations-api.visitors.errors.invalidReceptionCurrentCompany": undefined;
  "operations-api.visitors.errors.invalidReceptionSite": undefined;
  "operations-api.visitors.errors.notPropertyManager": undefined;
  "operations-api.visitors.errors.notWorkplaceManager": undefined;
  "operations-api.visitors.errors.receptionNotExistsOnSite": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToDetermineRecurringUpdateType": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToFindRecurringSettings": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentRecurringTypes": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorIsWalkIn": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorMissingProperty": { paramNames: string | number };
  "operations-api.visitors.errors.recurringAppointmentValidationErrorNotRecurring": undefined;
  "operations-api.visitors.errors.statusChangeDenied": undefined;
  "operations-api.visitors.errors.visitorAppointmentForBookingNotFound": { bookingUuid: string | number };
  "permissions-api.errors.equiemOnly": { roleName: string | number };
  "permissions-api.errors.grantDenied": { roleName: string | number, resourceName: string | number };
  "permissions-api.errors.invalidRoleTuple": { tuple: string | number };
  "permissions-api.errors.noRoleMatchingTuple": { tuple: string | number };
  "profile-api.errors.oneTimePassword.cannotGenerateCode": undefined;
  "profile-api.errors.oneTimePassword.cannotGenerateCodeWithErrorCode": { errorCode: string | number };
  "profile-api.errors.oneTimePassword.expiredCode": undefined;
  "profile-api.errors.oneTimePassword.incorrectCode": undefined;
  "profile-api.errors.oneTimePassword.invalidEmail": undefined;
  "profile-api.errors.oneTimePassword.invalidNumber": undefined;
  "profile-api.errors.oneTimePassword.numberOptedOut": undefined;
  "profile-api.errors.oneTimePassword.smsDisabled": undefined;
  "profile-api.errors.oneTimePassword.unknownIssue": undefined;
  "profile-api.errors.oneTimePassword.unverifiedEmail": undefined;
  "request-management-api.errors.buildingCategoryNonExistance": undefined;
  "request-management-api.errors.buildingNotFound": undefined;
  "request-management-api.errors.buildingsNotFound": undefined;
  "request-management-api.errors.buildingsNotFoundBySiteContext": undefined;
  "request-management-api.errors.cafmConfigurationAlreadyExistsForSiteForProvider": undefined;
  "request-management-api.errors.cafmConfigurationDeleteFailed": undefined;
  "request-management-api.errors.cafmConfigurationFailedToGeneratePassword": undefined;
  "request-management-api.errors.cafmConfigurationNotFound": { uuid: string | number };
  "request-management-api.errors.cafmConfigurationUpsertFailed": undefined;
  "request-management-api.errors.cafmConfigurationUpsertFailedCannotChangeProviderType": undefined;
  "request-management-api.errors.categoryCafmConfigurationDeleteFailed": undefined;
  "request-management-api.errors.categoryCafmConfigurationLinkAlreadyExists": undefined;
  "request-management-api.errors.categoryCafmConfigurationLinkAlreadyExistsForBuilding": undefined;
  "request-management-api.errors.categoryCafmConfigurationNotFound": { uuid: string | number };
  "request-management-api.errors.categoryCafmConfigurationSiteInconsistent": undefined;
  "request-management-api.errors.categoryCannotUpdateNonExistent": undefined;
  "request-management-api.errors.categoryNameTaken": undefined;
  "request-management-api.errors.categoryNotFound": undefined;
  "request-management-api.errors.categoryTypeNotFound": undefined;
  "request-management-api.errors.companyNotFound": undefined;
  "request-management-api.errors.completionDateBeforeReportedError": undefined;
  "request-management-api.errors.createCategoryFailed": undefined;
  "request-management-api.errors.createSubCategoryFailed": undefined;
  "request-management-api.errors.dateCompletedStatusError": undefined;
  "request-management-api.errors.dateFutureError": undefined;
  "request-management-api.errors.dateReportedFutureError": undefined;
  "request-management-api.errors.deleteCategoryFailed": undefined;
  "request-management-api.errors.deleteSubCategoryFailed": undefined;
  "request-management-api.errors.descriptionIsRequired": undefined;
  "request-management-api.errors.differentSiteAccessError": undefined;
  "request-management-api.errors.iconRequired": undefined;
  "request-management-api.errors.loadCategoryPresetsFailed": undefined;
  "request-management-api.errors.nameCannotBeEmpty": undefined;
  "request-management-api.errors.noAccessToDoThisOperation": undefined;
  "request-management-api.errors.noAccessToEditRequest": undefined;
  "request-management-api.errors.noAccessToViewRequest": undefined;
  "request-management-api.errors.presetNotFound": undefined;
  "request-management-api.errors.queueAgentsRequired": undefined;
  "request-management-api.errors.queueAlreadyActive": undefined;
  "request-management-api.errors.queueAlreadyInactive": undefined;
  "request-management-api.errors.queueNotFound": undefined;
  "request-management-api.errors.reportedDateAfterCompletionError": undefined;
  "request-management-api.errors.reporterCompanyNotFoundError": undefined;
  "request-management-api.errors.requestHasChanged": undefined;
  "request-management-api.errors.requestMissingCategoryOrSubcategory": undefined;
  "request-management-api.errors.requestNotFound": undefined;
  "request-management-api.errors.requestWasAlreadySent": undefined;
  "request-management-api.errors.siteNotFound": undefined;
  "request-management-api.errors.spaceNotFound": undefined;
  "request-management-api.errors.statusIsUsed": undefined;
  "request-management-api.errors.statusMinimumRequired": undefined;
  "request-management-api.errors.statusNotFound": undefined;
  "request-management-api.errors.statusWithSameName": undefined;
  "request-management-api.errors.subCategoryCannotUpdateNonExistent": undefined;
  "request-management-api.errors.subCategoryNameTaken": undefined;
  "request-management-api.errors.subcategoryMissmatchError": undefined;
  "request-management-api.errors.subcategoryNotFound": undefined;
  "request-management-api.errors.updateCategoryFailed": undefined;
  "request-management-api.errors.updateSubCategoryFailed": undefined;
  "request-management-api.errors.userNotFound": undefined;
  "syndicate-api.registrationFailure.companyDoesNotExist": undefined;
  "syndicate-api.registrationFailure.companyNotProvided": undefined;
  "syndicate-api.registrationFailure.completionEmailSent": { siteName: string | number };
  "syndicate-api.registrationFailure.contactSupport": undefined;
  "syndicate-api.registrationFailure.createAccountFailed": undefined;
  "syndicate-api.registrationFailure.createProfileFailed": undefined;
  "syndicate-api.registrationFailure.invalidEmail": undefined;
  "syndicate-api.registrationFailure.invalidToken": undefined;
  "syndicate-api.registrationFailure.registrationEmailSendFailed": undefined;
  "syndicate-api.registrationFailure.registrationFailedInAuth0": undefined;
  "syndicate-api.registrationFailure.saveProfileFailed": undefined;
  "syndicate-api.registrationFailure.siteNotExist": undefined;
  "syndicate-api.registrationFailure.siteNotFound": undefined;
  "syndicate-api.registrationFailure.unknownError": undefined;
  "syndicate-api.registrationFailure.userExists": undefined;
  "syndicate-api.registrationFailure.userExistsPersonal": undefined;
  "syndicate-api.registrationFailure.userNotExist": undefined;
  "syndicate-api.ubiparkFailure.noUbiparkCredentials": undefined;
  "syndicate-api.ubiparkFailure.ubiparkUserNotCreated": undefined;
  "syndicate-api.ubiparkFailure.unableToProcessRequest": undefined;
  "syndicate-api.ubiparkFailure.userNotAuthenticated": undefined;
  "syndicate-api.ubiparkFailure.userNotFound": undefined;
  "syndicate-api.userDestinationStatus.pendingEmailConfirmation": undefined;
  "syndicate-api.userDestinationStatus.pendingVerification": undefined;
  "syndicate-api.userDestinationStatus.pendingVerificationAndEmailConfirmation": undefined;
  "zero.graphql.errors.FORBIDDEN": undefined;
  "zero.graphql.errors.INTERNAL_SERVER_ERROR": undefined;
  "zero.graphql.errors.NOT_IMPLEMENTED": undefined;
  "zero.graphql.scalars.colour": undefined;
  "zero.graphql.scalars.datetime": undefined;
  "zero.graphql.scalars.email": undefined;
  "zero.graphql.scalars.jwt": undefined;
  "zero.graphql.scalars.mobilenumber.format": undefined;
  "zero.graphql.scalars.mobilenumber.not-mobile": undefined;
  "zero.graphql.scalars.time": undefined;
  "zero.graphql.scalars.timezone": undefined;
  "zero.graphql.scalars.uri": undefined;
  "zero.graphql.scalars.uuid": undefined;
}

/** @public */
export type ApplicationMessages = {
  "bookings.lib.addOnNameRequired": undefined;
  "bookings.lib.addOnTitleRequired": undefined;
  "bookings.lib.bookingWindowRangeError": undefined;
  "bookings.lib.connectStripeAccount": undefined;
  "bookings.lib.dateInThePast": undefined;
  "bookings.lib.endDateBeforeStart": undefined;
  "bookings.lib.enterBothContact": undefined;
  "bookings.lib.enterValidEmail": undefined;
  "bookings.lib.invalidDateFormat": undefined;
  "bookings.lib.invalidEndTime": undefined;
  "bookings.lib.invalidStartTime": undefined;
  "bookings.lib.isRequired": undefined;
  "bookings.lib.isSingleOption": { fieldName: string | number };
  "bookings.lib.maximumBookingIs": { time: number };
  "bookings.lib.minimumBookingIs": { time: number };
  "bookings.lib.minimumTimeMustBeLess": undefined;
  "bookings.lib.mustBeAfter": undefined;
  "bookings.lib.mustBeValidEmail": undefined;
  "bookings.lib.noOptionSelected": { fieldName: string | number };
  "bookings.lib.optionMustHaveQuantity": undefined;
  "bookings.lib.pleaseConfigureRates": undefined;
  "bookings.lib.pleaseFillAllChoices": undefined;
  "bookings.lib.pleaseSelectContact": undefined;
  "bookings.lib.pleaseSelectResourceType": undefined;
  "bookings.lib.pleaseSetStartDate": undefined;
  "bookings.lib.pleaseSpecifyDuration": undefined;
  "bookings.lib.provideAvailability": undefined;
  "bookings.lib.quantityIsRequired": { fieldName: string | number };
  "bookings.lib.requiredMinimumWeek": undefined;
  "bookings.lib.requiredPickOption": { name: string | number };
  "bookings.lib.selectDate": undefined;
  "bookings.lib.selectDefaultPaymentMethod": undefined;
  "bookings.lib.selectEndTime": undefined;
  "bookings.lib.selectStartTime": undefined;
  "bookings.lib.startBeforeEndTime": undefined;
  "bookings.lib.startTimeBeforeEnd": undefined;
  "bookings.lib.thisField": undefined;
  "bookings.lib.titleMinimumLength": { length: string | number };
  "bookings.lib.validDuration": undefined;
  "bookings.lib.valueGreaterThan": undefined;
  "bookings.lib.varIsRequired": { name: string | number };
  "bookings.common.bookingCharges": undefined;
  "bookings.common.daysOfWeek.sunday": undefined;
  "bookings.common.daysOfWeek.monday": undefined;
  "bookings.common.daysOfWeek.tuesday": undefined;
  "bookings.common.daysOfWeek.wednesday": undefined;
  "bookings.common.daysOfWeek.thursday": undefined;
  "bookings.common.daysOfWeek.friday": undefined;
  "bookings.common.daysOfWeek.saturday": undefined;
  "bookings.operations.acceptTheTerms": undefined;
  "bookings.operations.acceptTheTermsConditions": undefined;
  "bookings.operations.activityLog": undefined;
  "bookings.operations.addAdminNote": undefined;
  "bookings.operations.addCharge": undefined;
  "bookings.operations.addInvoiceDetail": undefined;
  "bookings.operations.addNote": undefined;
  "bookings.operations.addOnCost": undefined;
  "bookings.operations.addOns": undefined;
  "bookings.operations.addRefund": undefined;
  "bookings.operations.addTitleForBookingTooltip": undefined;
  "bookings.operations.addedAdminNote": undefined;
  "bookings.operations.addedHostNote": undefined;
  "bookings.operations.additionalCharge": undefined;
  "bookings.operations.additionalCharges": undefined;
  "bookings.operations.addsOn": undefined;
  "bookings.operations.adjustmentLineItem": { user: string | number };
  "bookings.operations.adminNote": undefined;
  "bookings.operations.adminNotePlaceholder": undefined;
  "bookings.operations.adminNoteTooltip": undefined;
  "bookings.operations.allReservations": undefined;
  "bookings.operations.approvalRequiredAlert": undefined;
  "bookings.operations.approveOrDecline": undefined;
  "bookings.operations.approveOrDeclineAction": undefined;
  "bookings.operations.approveOrDeclineMessage": { bookingReference: string | number };
  "bookings.operations.approveThisBooking": undefined;
  "bookings.operations.areYouSureCancel": { bookingReference: string | number };
  "bookings.operations.availability": undefined;
  "bookings.operations.availabilityDays": undefined;
  "bookings.operations.availabilitiesOverflow": { count: number };
  "bookings.operations.billingDetails": undefined;
  "bookings.operations.book": undefined;
  "bookings.operations.bookNow": undefined;
  "bookings.operations.booking": undefined;
  "bookings.operations.bookingNotFound": undefined;
  "bookings.operations.bookingApprovalSuccess": { bookingReference: string | number };
  "bookings.operations.bookingCancelledSuccess": undefined;
  "bookings.operations.bookingCost": undefined;
  "bookings.operations.bookingDeclinedByManager": undefined;
  "bookings.operations.bookingDeclinedByManagerForTheReason": { reason: string | number };
  "bookings.operations.bookingDeclinedSuccess": { bookingReference: string | number };
  "bookings.operations.bookingDetailsTitle": undefined;
  "bookings.operations.bookingHasBeenCancelled": { bookingReference: string | number };
  "bookings.operations.bookingHasBeenConfirmed": { bookingReference: string | number };
  "bookings.operations.bookingHasBeenRequested": undefined;
  "bookings.operations.bookingNote": undefined;
  "bookings.operations.bookingNotePlaceholder": undefined;
  "bookings.operations.bookingNoteTooltip": undefined;
  "bookings.operations.bookingReference": { reference: string | number };
  "bookings.operations.bookingReferenceUpdated": { bookingReference: string | number };
  "bookings.operations.bookingReqManagerApproval": undefined;
  "bookings.operations.bookingSummary": undefined;
  "bookings.operations.bookingTime": undefined;
  "bookings.operations.bookings": undefined;
  "bookings.operations.bookingsOperations": undefined;
  "bookings.operations.bookingsReferenceDetails": { bookingReference: string | number };
  "bookings.operations.browse": undefined;
  "bookings.operations.browseBookAndViewBookings": undefined;
  "bookings.operations.calendar": undefined;
  "bookings.operations.calendarView.fiveDays": undefined;
  "bookings.operations.calendarView.oneDay": undefined;
  "bookings.operations.calendarView.oneMonth": undefined;
  "bookings.operations.calendarView.sevenDays": undefined;
  "bookings.operations.cancelBooking": undefined;
  "bookings.operations.cancelBookingConfirmation": undefined;
  "bookings.operations.cancelBookingEditConfirmation": undefined;
  "bookings.operations.cancellationCost": undefined;
  "bookings.operations.cancelled": undefined;
  "bookings.operations.cancelledByHost": undefined;
  "bookings.operations.cancelledBySystem": undefined;
  "bookings.operations.cancelledByUser": undefined;
  "bookings.operations.cancelledDeletion": { name: string | number };
  "bookings.operations.changedTheResource": undefined;
  "bookings.operations.changedTheUser": undefined;
  "bookings.operations.chargeAmount": undefined;
  "bookings.operations.chooseResource": undefined;
  "bookings.operations.cleanUpTime": undefined;
  "bookings.operations.config": undefined;
  "bookings.operations.configuration": undefined;
  "bookings.operations.configurationIsRequired": undefined;
  "bookings.operations.confirmBooking": undefined;
  "bookings.operations.confirmChanges": undefined;
  "bookings.operations.costs": undefined;
  "bookings.operations.createNewBooking": undefined;
  "bookings.operations.createdBooking": undefined;
  "bookings.operations.creditAccount": undefined;
  "bookings.operations.dateAndTime": undefined;
  "bookings.operations.declineBookingReason": undefined;
  "bookings.operations.declineBookingReasonPlaceholder": undefined;
  "bookings.operations.declineBookingReasonTooltip": undefined;
  "bookings.operations.declineThisBooking": undefined;
  "bookings.operations.duration": undefined;
  "bookings.operations.editBooking": undefined;
  "bookings.operations.editBookingReference": { bookingReference: string | number };
  "bookings.operations.editOrCancelPolicy": undefined;
  "bookings.operations.failedToCancelDeletion": undefined;
  "bookings.operations.failedToDuplicate": { name: string | number };
  "bookings.operations.failedToLoad": undefined;
  "bookings.operations.filters": undefined;
  "bookings.operations.getQuickView": undefined;
  "bookings.operations.getStartedByBrowsingCatalogue": undefined;
  "bookings.operations.goToPayment": undefined;
  "bookings.operations.hasBeenPublished": { name: string | number };
  "bookings.operations.hasBeenSavedToDraft": { name: string | number };
  "bookings.operations.hasCancelledTheBooking": undefined;
  "bookings.operations.hasChargedTheBooking": undefined;
  "bookings.operations.hasPartiallyRefundedTheBooking": undefined;
  "bookings.operations.hostNote": undefined;
  "bookings.operations.iHaveInformed": undefined;
  "bookings.operations.ifYouWishToEditContactManager": undefined;
  "bookings.operations.invoiceContract": undefined;
  "bookings.operations.list": undefined;
  "bookings.operations.location": undefined;
  "bookings.operations.makeBooking": undefined;
  "bookings.operations.newBooking": undefined;
  "bookings.operations.noAvailableTime": undefined;
  "bookings.operations.noAvailability": undefined;
  "bookings.operations.noBillingCustomers": undefined;
  "bookings.operations.noBookingsOnDay": undefined;
  "bookings.operations.noBookingsYet": undefined;
  "bookings.operations.noCreditAccountsError": undefined;
  "bookings.operations.noResourcesFound": undefined;
  "bookings.operations.noTakeMeBack": undefined;
  "bookings.operations.notes": undefined;
  "bookings.operations.noteValidation": { value: string | number };
  "bookings.operations.openInNewTab": undefined;
  "bookings.operations.orAddInvoiceManually": undefined;
  "bookings.operations.past": undefined;
  "bookings.operations.payment": undefined;
  "bookings.operations.paymentMethod": undefined;
  "bookings.operations.paymentStatus": undefined;
  "bookings.operations.payViaCreditCard": undefined;
  "bookings.operations.payViaCredits": undefined;
  "bookings.operations.payViaInvoice": undefined;
  "bookings.operations.personCapacity": { prefix: string | number, capacity: string | number, suffix: string | number };
  "bookings.operations.personCapacityDisplay": { displayCapacity: string | number };
  "bookings.operations.pleaseContactManagerToCancelEdit": undefined;
  "bookings.operations.pleaseEnterContactFullnameEmail": undefined;
  "bookings.operations.pleaseEnterValidEmail": undefined;
  "bookings.operations.pleaseProvideCreditAccountDetails": undefined;
  "bookings.operations.pleaseProvideCreditCardDetails": undefined;
  "bookings.operations.pleaseSelectBillingCustomer": undefined;
  "bookings.operations.pleaseSelectHost": undefined;
  "bookings.operations.pleaseSelectOneOfContact": undefined;
  "bookings.operations.pleaseSelectPaymentMethod": undefined;
  "bookings.operations.pleaseSpecifyAmount": undefined;
  "bookings.operations.plusMore": { count: string | number };
  "bookings.operations.printRunSheet": undefined;
  "bookings.operations.reasonForAdditionalCharge": undefined;
  "bookings.operations.reasonForRefund": undefined;
  "bookings.operations.reasonsOfTheAdjustment": undefined;
  "bookings.operations.refNo": undefined;
  "bookings.operations.refundAmount": undefined;
  "bookings.operations.refundLineItem": { item: string | number, user: string | number };
  "bookings.operations.refund": undefined;
  "bookings.operations.refunds": undefined;
  "bookings.operations.refundsAndAdditionalCharges": undefined;
  "bookings.operations.removeImage": undefined;
  "bookings.operations.requestBooking": undefined;
  "bookings.operations.resource": undefined;
  "bookings.operations.resourceNotFound": undefined;
  "bookings.operations.resourceHasBeenDuplicated": { name: string | number };
  "bookings.operations.resourceNameAndLocation": undefined;
  "bookings.operations.resourceRates": undefined;
  "bookings.operations.roomBooking": undefined;
  "bookings.operations.roomConfiguration": undefined;
  "bookings.operations.saveDetails": undefined;
  "bookings.operations.saveNote": undefined;
  "bookings.operations.savedInvoiceDetails": undefined;
  "bookings.operations.savedCardDetails": undefined;
  "bookings.operations.scrollDownToLoad": undefined;
  "bookings.operations.selectACreditAccount": undefined;
  "bookings.operations.selectHostTooltip": undefined;
  "bookings.operations.selectPayment": undefined;
  "bookings.operations.showLess": undefined;
  "bookings.operations.showMore": undefined;
  "bookings.operations.startTime": undefined;
  "bookings.operations.starts": { time: string | number };
  "bookings.operations.status.approved": undefined;
  "bookings.operations.status.declined": undefined;
  "bookings.operations.status.paymentFailed": undefined;
  "bookings.operations.status.pendingApproval": undefined;
  "bookings.operations.status.pendingPayment": undefined;
  "bookings.operations.spaceConfig": undefined;
  "bookings.operations.spaceConfigTooltip": undefined;
  "bookings.operations.tax": undefined;
  "bookings.operations.thereNoUpcomingBookings": undefined;
  "bookings.operations.thisBookingCanNoLongerBeChanged": undefined;
  "bookings.operations.thumbnail": undefined;
  "bookings.operations.titleValidation": { value: string | number };
  "bookings.operations.totalCost": undefined;
  "bookings.operations.unableCancelBooking": undefined;
  "bookings.operations.unableEditBooking": undefined;
  "bookings.operations.unableMakeBooking": undefined;
  "bookings.operations.unspecified": undefined;
  "bookings.operations.upcoming": undefined;
  "bookings.operations.upcomingBookings": undefined;
  "bookings.operations.updateBooking": undefined;
  "bookings.operations.updatedApprovalStatus": undefined;
  "bookings.operations.updatedTheBooking": undefined;
  "bookings.operations.userIsRequired": undefined;
  "bookings.operations.view": undefined;
  "bookings.operations.viewBooking": undefined;
  "bookings.operations.when": undefined;
  "bookings.operations.yesCancelBooking": undefined;
  "bookings.operations.youCanNoLongerUseCredit": undefined;
  "bookings.reports.bookingCount": { count: number };
  "bookings.reports.bookingCreated": undefined;
  "bookings.reports.bookingStartDate": undefined;
  "bookings.reports.cancelled": undefined;
  "bookings.reports.costOnly": undefined;
  "bookings.reports.detail": undefined;
  "bookings.reports.generateCsv": undefined;
  "bookings.reports.paymentMethod": undefined;
  "bookings.reports.reconciliationReport": undefined;
  "bookings.reports.reporting": undefined;
  "bookings.reports.resource": undefined;
  "bookings.reports.selectACompany": undefined;
  "bookings.reports.summary": undefined;
  "bookings.reports.yardiExport": undefined;
  "bookings.discounts.activity.titleChanged": undefined;
  "bookings.discounts.activity.startDateChanged": undefined;
  "bookings.discounts.activity.endDateChanged": undefined;
  "bookings.discounts.activity.startTimeChanged": undefined;
  "bookings.discounts.activity.endTimeChanged": undefined;
  "bookings.discounts.activity.percentageChanged": undefined;
  "bookings.discounts.activity.numberOfDaysChanged": undefined;
  "bookings.discounts.activity.availabilityDaysChanged": undefined;
  "bookings.discounts.activity.resourcesChanged": undefined;
  "bookings.discounts.activity.discountCreated": undefined;
  "bookings.discounts.activity.discountDeleted": undefined;
  "bookings.discounts.activity.noActivity": undefined;
  "bookings.discounts.availabilitiesDaysFieldError": undefined;
  "bookings.discounts.name": undefined;
  "bookings.discounts.discount": undefined;
  "bookings.discounts.resources": undefined;
  "bookings.discounts.status": undefined;
  "bookings.discounts.addNewDiscount": undefined;
  "bookings.discounts.editDiscount": undefined;
  "bookings.discounts.editDiscountHelpText": undefined;
  "bookings.discounts.details": undefined;
  "bookings.discounts.earlyBooking": undefined;
  "bookings.discounts.periodic": undefined;
  "bookings.discounts.emptyState": undefined;
  "bookings.discounts.priceWithDiscount": undefined;
  "bookings.discounts.saveDiscount": { percentage: string | number };
  "bookings.discounts.saveUptoDiscount": { percentage: string | number };
  "bookings.discounts.bookDaysInAdvance": { count: number };
  "bookings.discounts.bookInDays": { days: string | number };
  "bookings.discounts.discountAvailable": undefined;
  "bookings.discounts.earlyBookingHelpText": undefined;
  "bookings.discounts.weekdayList": { dayList: string | number };
  "bookings.discounts.periodicBookingHelpText": undefined;
  "bookings.discounts.titleField": undefined;
  "bookings.discounts.titleFieldPlaceholder": undefined;
  "bookings.discounts.titleFieldHelpText": undefined;
  "bookings.discounts.numberOfDaysField": undefined;
  "bookings.discounts.numberOfDaysFieldPlaceholder": undefined;
  "bookings.discounts.numberOfDaysFieldError": undefined;
  "bookings.discounts.percentageField": undefined;
  "bookings.discounts.percentageFieldPlaceholder": undefined;
  "bookings.discounts.percentageFieldError": undefined;
  "bookings.discounts.resourcesFieldPlaceholder": undefined;
  "bookings.discounts.discountWasSuccessfullyCreated": { name: string | number };
  "bookings.discounts.discountWasSuccessfullyUpdated": { name: string | number };
  "bookings.discounts.discountWasSuccessfullyDeleted": { name: string | number };
  "bookings.discounts.discountDetails": undefined;
  "bookings.discounts.resourceAssgined": undefined;
  "bookings.discounts.daysBeforeBooking": { count: number };
  "bookings.discounts.discountStatus.active": undefined;
  "bookings.discounts.discountStatus.expired": undefined;
  "bookings.discounts.discountStatus.deleted": undefined;
  "bookings.discounts.discountStatus.upcoming": undefined;
  "bookings.discounts.deleteWarning": undefined;
  "bookings.discounts.formCloseWarning": undefined;
  "bookings.discounts.history": undefined;
  "bookings.discounts.activityHistoryLimitWarning": undefined;
  "bookings.resources.accessControl": undefined;
  "bookings.resources.accessGroup": undefined;
  "bookings.resources.accessGroupTooltip": undefined;
  "bookings.resources.accessGroupVisitors": undefined;
  "bookings.resources.accessGroupVisitorsTooltip": undefined;
  "bookings.resources.accessibility": undefined;
  "bookings.resources.activeWarning": { count: number };
  "bookings.resources.addAddOn": undefined;
  "bookings.resources.addAnotherAudience": undefined;
  "bookings.resources.addAnotherOption": undefined;
  "bookings.resources.addCancellationRate": undefined;
  "bookings.resources.addExtras": undefined;
  "bookings.resources.addHours": undefined;
  "bookings.resources.addNewResource": undefined;
  "bookings.resources.addOnName": undefined;
  "bookings.resources.addOnNameHint": undefined;
  "bookings.resources.addOnTitle": undefined;
  "bookings.resources.addOnTitleHint": undefined;
  "bookings.resources.addOnType": undefined;
  "bookings.resources.addOns": undefined;
  "bookings.resources.addPhotos": undefined;
  "bookings.resources.addPhotosAndVideos": undefined;
  "bookings.resources.addSlot": undefined;
  "bookings.resources.additionalSettings": undefined;
  "bookings.resources.allowRecurringBooking": undefined;
  "bookings.resources.allowRecurringBookingTooltip": undefined;
  "bookings.resources.allowRecurringBookingWarning": undefined;
  "bookings.resources.allowRecurringBookingInfo": undefined;
  "bookings.resources.allowVisitorInvites": undefined;
  "bookings.resources.allowVisitorInvitesTooltip": undefined;
  "bookings.resources.amenities": undefined;
  "bookings.resources.audience": undefined;
  "bookings.resources.audioVisualDisplay": undefined;
  "bookings.resources.automaticallyApproveBookings": undefined;
  "bookings.resources.availabilities": undefined;
  "bookings.resources.availability": undefined;
  "bookings.resources.availabilityType": undefined;
  "bookings.resources.boardroom": undefined;
  "bookings.resources.bookingApprovals": undefined;
  "bookings.resources.bookingApprovalsError": undefined;
  "bookings.resources.bookingApprovalsErrorCredits": undefined;
  "bookings.resources.bookingApprovalsErrorCreditsAndCreditCard": undefined;
  "bookings.resources.bookingApprovalsHint": undefined;
  "bookings.resources.bookingType": undefined;
  "bookings.resources.bookingWindowMaxHint": undefined;
  "bookings.resources.bookingWindowMaxHintV2": undefined;
  "bookings.resources.bookingWindowMinHint": undefined;
  "bookings.resources.bookingWindowMinHintV2": undefined;
  "bookings.resources.breakOutArea": undefined;
  "bookings.resources.businessLounge": undefined;
  "bookings.resources.cabaret": undefined;
  "bookings.resources.cafe": undefined;
  "bookings.resources.calendarEmailAddress": undefined;
  "bookings.resources.calendarEmailAddressHint": undefined;
  "bookings.resources.calendarEmailAddressTooltip": undefined;
  "bookings.resources.calendarSync": undefined;
  "bookings.resources.cancelBodyCloseTab": undefined;
  "bookings.resources.cancelDeletion": undefined;
  "bookings.resources.cancelationRatesHint": undefined;
  "bookings.resources.cancellationPermissions": undefined;
  "bookings.resources.cancellationRates": undefined;
  "bookings.resources.catalogueCurrentlyEmpty": undefined;
  "bookings.resources.choiceName": undefined;
  "bookings.resources.choices": undefined;
  "bookings.resources.classroom": undefined;
  "bookings.resources.clickshare": undefined;
  "bookings.resources.cocktail": undefined;
  "bookings.resources.companyChangeWarning": { originalCompanyName: string | number, resourceName: string | number };
  "bookings.resources.configurations": undefined;
  "bookings.resources.containerTitle": { title: string | number };
  "bookings.resources.countPersonCapacity": { count: string | number };
  "bookings.resources.createBookingResource": undefined;
  "bookings.resources.createExtra": undefined;
  "bookings.resources.createdBy": undefined;
  "bookings.resources.createdByHint": undefined;
  "bookings.resources.cropAndUploadImage": undefined;
  "bookings.resources.customSession": undefined;
  "bookings.resources.danceFloor": undefined;
  "bookings.resources.daysBefore": undefined;
  "bookings.resources.daysBeforeHint": undefined;
  "bookings.resources.defaultResourceRates": undefined;
  "bookings.resources.defaultSiteAudienceRates": undefined;
  "bookings.resources.deleted": undefined;
  "bookings.resources.deletionInfoActiveBookings": { count: number, name: string | number };
  "bookings.resources.deletionInfoAfterDate": undefined;
  "bookings.resources.deletionInfoAfterDateChildren": { count: number };
  "bookings.resources.deletionInfoAfterDateHistoricalBookings": undefined;
  "bookings.resources.deletionInfoAfterDateNoCancelDeletion": undefined;
  "bookings.resources.deletionInfoAfterDateNoListing": undefined;
  "bookings.resources.deletionInfoAfterDateParents": { count: number };
  "bookings.resources.deletionInfoCanBeScheduled": { date: string | number };
  "bookings.resources.deletionInfoImmediately": undefined;
  "bookings.resources.deletionInfoImmediatelyNoBrowsing": undefined;
  "bookings.resources.deletionInfoImmediatelyNoEditBookings": undefined;
  "bookings.resources.deletionInfoImmediatelyNoCreateBookings": undefined;
  "bookings.resources.deletionInfoImmediatelyViewAndCancelBookings": undefined;
  "bookings.resources.deletionInfoSummary": undefined;
  "bookings.resources.deletionInfoTitle": { name: string | number };
  "bookings.resources.deletionInfoWillHappen": undefined;
  "bookings.resources.dependentResources": undefined;
  "bookings.resources.dependentsConflict": undefined;
  "bookings.resources.descriptionPlaceholder": undefined;
  "bookings.resources.details": undefined;
  "bookings.resources.draft": undefined;
  "bookings.resources.duplicate": undefined;
  "bookings.resources.durationInMinutes": undefined;
  "bookings.resources.durationInMinutesHint": undefined;
  "bookings.resources.durationInMinutesTooltip": undefined;
  "bookings.resources.durationType": undefined;
  "bookings.resources.editAudience": undefined;
  "bookings.resources.editCancellationPolicy": undefined;
  "bookings.resources.editCancellationPolicyHint": undefined;
  "bookings.resources.editCancellationRate": undefined;
  "bookings.resources.editExtras": undefined;
  "bookings.resources.editResource": undefined;
  "bookings.resources.editSlot": undefined;
  "bookings.resources.editingCancellationPermissions": undefined;
  "bookings.resources.enableRecurringBooking": undefined;
  "bookings.resources.endDate": undefined;
  "bookings.resources.endOfBusinessHours": undefined;
  "bookings.resources.enterCalendarIcsUrl": undefined;
  "bookings.resources.enterCalendarIcsUrlHint": undefined;
  "bookings.resources.eventSpace": undefined;
  "bookings.resources.extras": undefined;
  "bookings.resources.extrasName": undefined;
  "bookings.resources.extrasNameHint": undefined;
  "bookings.resources.extrasNameIsRequired": undefined;
  "bookings.resources.extrasTitle": undefined;
  "bookings.resources.extrasTitleHint": undefined;
  "bookings.resources.extrasTitleIsRequired": undefined;
  "bookings.resources.extrasType": undefined;
  "bookings.resources.facilities": undefined;
  "bookings.resources.failedToLoadInfo": undefined;
  "bookings.resources.failedToValidateIcsLink": undefined;
  "bookings.resources.features": undefined;
  "bookings.resources.feeApplies": undefined;
  "bookings.resources.fixedRate": undefined;
  "bookings.resources.flexibleBooking": undefined;
  "bookings.resources.flexibleBookingHint": undefined;
  "bookings.resources.freeText": undefined;
  "bookings.resources.freeTextToProvide": undefined;
  "bookings.resources.from": undefined;
  "bookings.resources.fullDay": undefined;
  "bookings.resources.fullSession": undefined;
  "bookings.resources.fullSessionHint": undefined;
  "bookings.resources.gallery": undefined;
  "bookings.resources.galleryModalError": undefined;
  "bookings.resources.halfDay": undefined;
  "bookings.resources.halfDayDurationHours": undefined;
  "bookings.resources.hasBeenSavedAndPublished": { title: string | number };
  "bookings.resources.hasBeenSavedToDraft": { title: string | number };
  "bookings.resources.hearingImpairmentLoop": undefined;
  "bookings.resources.highSpeedWifi": undefined;
  "bookings.resources.hourly": undefined;
  "bookings.resources.hourlyAfterHours": undefined;
  "bookings.resources.hourlyWeekend": undefined;
  "bookings.resources.icsLink": undefined;
  "bookings.resources.icsLinkCouldntConnect": undefined;
  "bookings.resources.icsLinkHint": undefined;
  "bookings.resources.icsMustBeAValidUrl": undefined;
  "bookings.resources.invalidCompany": undefined;
  "bookings.resources.kitchenette": undefined;
  "bookings.resources.location": undefined;
  "bookings.resources.makeThisAddOnRequired": undefined;
  "bookings.resources.makeThisExtraRequired": undefined;
  "bookings.resources.managedBy": undefined;
  "bookings.resources.managedByAnyone": undefined;
  "bookings.resources.managedByMe": undefined;
  "bookings.resources.maxBookingTimeInMinutes": undefined;
  "bookings.resources.maxQuantity": undefined;
  "bookings.resources.maximumTimeBeforeEndOfBooking": undefined;
  "bookings.resources.maximumTimeBeforeStartOfBooking": undefined;
  "bookings.resources.maximumTimeHint": undefined;
  "bookings.resources.meetingRoom": undefined;
  "bookings.resources.microphone": undefined;
  "bookings.resources.minBookingTimeInMinutes": undefined;
  "bookings.resources.minimumTimeBeforeStartOfBooking": undefined;
  "bookings.resources.minimumTimeHint": undefined;
  "bookings.resources.multiChoice": undefined;
  "bookings.resources.multiSiteWarning": undefined;
  "bookings.resources.newResource": undefined;
  "bookings.resources.noticePeriod": undefined;
  "bookings.resources.noticePeriodHint": undefined;
  "bookings.resources.optionToSelect": undefined;
  "bookings.resources.overview": undefined;
  "bookings.resources.paymentMethods": undefined;
  "bookings.resources.pendingDeletion": undefined;
  "bookings.resources.permissions": undefined;
  "bookings.resources.personCapacity": { count: string | number };
  "bookings.resources.phoneChargers": undefined;
  "bookings.resources.photos": undefined;
  "bookings.resources.photosAndVideos": undefined;
  "bookings.resources.preparationTime": undefined;
  "bookings.resources.preparationTimeAfter": undefined;
  "bookings.resources.preparationTimeBefore": undefined;
  "bookings.resources.preparationTimeHint": undefined;
  "bookings.resources.priceEach": undefined;
  "bookings.resources.printer": undefined;
  "bookings.resources.projectSpace": undefined;
  "bookings.resources.projector": undefined;
  "bookings.resources.properties": undefined;
  "bookings.resources.publish": undefined;
  "bookings.resources.published": undefined;
  "bookings.resources.rateType": undefined;
  "bookings.resources.rates": undefined;
  "bookings.resources.requiredFieldMissing": undefined;
  "bookings.resources.resouceCapacity": undefined;
  "bookings.resources.resouceCapacityHint": undefined;
  "bookings.resources.resourceAddress": undefined;
  "bookings.resources.resourceAvailability": undefined;
  "bookings.resources.resourceBuilding": undefined;
  "bookings.resources.resourceBuildingHint": undefined;
  "bookings.resources.resourceDeleteFailed": { name: string | number };
  "bookings.resources.resourceDeleteScheduled": { name: string | number, date: string | number };
  "bookings.resources.resourceDependentsHint": undefined;
  "bookings.resources.resourceDescrption": undefined;
  "bookings.resources.resourceDetails": undefined;
  "bookings.resources.resourceLevel": undefined;
  "bookings.resources.resourceLocation": undefined;
  "bookings.resources.resourceName": undefined;
  "bookings.resources.resourceThumbnail": undefined;
  "bookings.resources.resourceTitle": undefined;
  "bookings.resources.resourceTitleHint": undefined;
  "bookings.resources.resourceTitleTimeHint": undefined;
  "bookings.resources.resourceType": undefined;
  "bookings.resources.resourceTypeFull": undefined;
  "bookings.resources.restroom": undefined;
  "bookings.resources.roomConfiguration": undefined;
  "bookings.resources.roomFeatures": undefined;
  "bookings.resources.saveAndDraft": undefined;
  "bookings.resources.saveAndPublish": undefined;
  "bookings.resources.scheduleDeletion": undefined;
  "bookings.resources.scheduleResourceDeletion": undefined;
  "bookings.resources.scheduleResourceForDeletionAfter": undefined;
  "bookings.resources.scheduleResourceForDeletionAfterHint": undefined;
  "bookings.resources.searchForResources": undefined;
  "bookings.resources.selectAudience": undefined;
  "bookings.resources.selectCompany": undefined;
  "bookings.resources.selectDependentResources": undefined;
  "bookings.resources.selectLevel": undefined;
  "bookings.resources.selectSite": undefined;
  "bookings.resources.sharedFacilities": undefined;
  "bookings.resources.showPaidResources": undefined;
  "bookings.resources.singleChoice": undefined;
  "bookings.resources.singleOption": undefined;
  "bookings.resources.slots": undefined;
  "bookings.resources.slotsHint": undefined;
  "bookings.resources.startDate": undefined;
  "bookings.resources.startOfBusinessHours": undefined;
  "bookings.resources.takeAVideoTour": undefined;
  "bookings.resources.tax": undefined;
  "bookings.resources.taxRate": undefined;
  "bookings.resources.teaCoffee": undefined;
  "bookings.resources.teleconferencing": undefined;
  "bookings.resources.television": undefined;
  "bookings.resources.termsAndConditions": undefined;
  "bookings.resources.theatre": undefined;
  "bookings.resources.timeSlot": undefined;
  "bookings.resources.timeSlotDaysLabel": undefined;
  "bookings.resources.timeSlotHoursLabel": undefined;
  "bookings.resources.timeSlotsAlert": undefined;
  "bookings.resources.to": undefined;
  "bookings.resources.tooltipText": undefined;
  "bookings.resources.tooltipTextHint": undefined;
  "bookings.resources.typeIsRequired": undefined;
  "bookings.resources.uShaped": undefined;
  "bookings.resources.unableToLoadResource": undefined;
  "bookings.resources.uniqueCancellationRatesError": undefined;
  "bookings.resources.uniqueSiteAudiencesError": undefined;
  "bookings.resources.usersCanEditCancelTheBooking": undefined;
  "bookings.resources.usersCanEditHint": undefined;
  "bookings.resources.videoConferencing": undefined;
  "bookings.resources.videoLink": undefined;
  "bookings.resources.videoLinkError": undefined;
  "bookings.resources.videoLinkPlaceholder": undefined;
  "bookings.resources.videoLinkTooltip": undefined;
  "bookings.resources.videoTitle": undefined;
  "bookings.resources.videoTitlePlaceholder": undefined;
  "bookings.resources.weekday": undefined;
  "bookings.resources.whiteboard": undefined;
  "bookings.resources.whoCanBookAll": undefined;
  "bookings.resources.whoCanBookGroup": undefined;
  "bookings.resources.whoCanBookHint": undefined;
  "bookings.resources.whoCanBookThisResource": undefined;
  "bookings.resources.workstation": undefined;
  "bookings.settings.accountId": { accountId: string | number };
  "bookings.settings.addConfiguration": undefined;
  "bookings.settings.addFacility": undefined;
  "bookings.settings.addFeature": undefined;
  "bookings.settings.addType": undefined;
  "bookings.settings.backToSettings": undefined;
  "bookings.settings.billingDetails": undefined;
  "bookings.settings.billingDetailsTooltip": undefined;
  "bookings.settings.blockOutDateDeleted": undefined;
  "bookings.settings.blockOutDates": undefined;
  "bookings.settings.blockOutDelete": undefined;
  "bookings.settings.blockOutManage": undefined;
  "bookings.settings.blockOutTitle": undefined;
  "bookings.settings.blockOutTitleHint": undefined;
  "bookings.settings.bookingSetting": undefined;
  "bookings.settings.cannotEditDefault": undefined;
  "bookings.settings.ccResourceWarn": { count: number };
  "bookings.settings.completingConnectingYourAccount": undefined;
  "bookings.settings.completingStripeConnect": undefined;
  "bookings.settings.connectStripeAccount": undefined;
  "bookings.settings.disconnectStripeAccount": undefined;
  "bookings.settings.enableBillingDetails": undefined;
  "bookings.settings.enablePaymentAdjustment": undefined;
  "bookings.settings.endDate": undefined;
  "bookings.settings.endTime": undefined;
  "bookings.settings.failedToConnect": undefined;
  "bookings.settings.incompleteStateInformation": undefined;
  "bookings.settings.invalidToken": undefined;
  "bookings.settings.invoiceDetails": undefined;
  "bookings.settings.missingConnectionCode": undefined;
  "bookings.settings.name": undefined;
  "bookings.settings.newBlockOutDate": undefined;
  "bookings.settings.paymentAdjustment": undefined;
  "bookings.settings.paymentAdjustmentHelp": undefined;
  "bookings.settings.paymentAdjustmentMax": undefined;
  "bookings.settings.paymentAdjustmentMaxHelp": undefined;
  "bookings.settings.paymentDetails": undefined;
  "bookings.settings.period": undefined;
  "bookings.settings.pleaseEnterAValidDate": undefined;
  "bookings.settings.pleaseEnterAValidTime": undefined;
  "bookings.settings.pleaseFillTheTitle": undefined;
  "bookings.settings.pleaseSelectADate": undefined;
  "bookings.settings.pleaseSelectAResource": undefined;
  "bookings.settings.requireBillingCustomerExplainer": undefined;
  "bookings.settings.resourceConfiguration": undefined;
  "bookings.settings.resourceFeatures": undefined;
  "bookings.settings.resourceFeaturesParagraph1": undefined;
  "bookings.settings.resourceFeaturesParagraph2": undefined;
  "bookings.settings.resourceType": undefined;
  "bookings.settings.resourceTypeParagraph1": undefined;
  "bookings.settings.resourceTypeParagraph2": undefined;
  "bookings.settings.resources": undefined;
  "bookings.settings.roomConfigurationParagraph1": undefined;
  "bookings.settings.roomConfigurationParagraph2": undefined;
  "bookings.settings.roomConfigurations": undefined;
  "bookings.settings.searchForResources": undefined;
  "bookings.settings.settingsFailedToLoad": undefined;
  "bookings.settings.sharedFacilities": undefined;
  "bookings.settings.sharedFacilitiesParagraph1": undefined;
  "bookings.settings.sharedFacilitiesParagraph2": undefined;
  "bookings.settings.startDate": undefined;
  "bookings.settings.startShouldBeBeforeEndDateAndTime": undefined;
  "bookings.settings.startTime": undefined;
  "bookings.settings.stateNotProvided": undefined;
  "bookings.settings.stringContactSupport": { message: string | number };
  "bookings.settings.stripeAccount": undefined;
  "bookings.settings.successfullyCreateBlockOutDate": undefined;
  "bookings.settings.successfullyDisconnectedStripeAccount": undefined;
  "bookings.settings.unableToFindPaymentDetails": undefined;
  "bookings.settings.unableToLoadPaymentDetails": undefined;
  "bookings.settings.unknownError": undefined;
  "bookings.settings.unknownParametersOnStripeConnectInboundLink": undefined;
  "bookings.settings.weAreUnableToFindYourStripeConnectionDetails": undefined;
  "bookings.visitors.addVisitors": undefined;
  "bookings.visitors.addVisitorsOnCreateInfo": undefined;
  "bookings.visitors.addVisitorsOnUpdateInfo": undefined;
  "bookings.visitors.addVisitorsPendingApprovalInfo": undefined;
  "bookings.visitors.appointmentCreateError": undefined;
  "bookings.visitors.appointmentCreateInvalidBookingStatus": { status: string | number };
  "bookings.visitors.createAppointment": undefined;
  "bookings.visitors.updateAppointment": undefined;
  "bookings.visitors.updateVisitorsInfo": undefined;
  "bookings.visitors.visitorsAdded": { count: number };
  "breadcrumbs.all": undefined;
  "breadcrumbs.bookings": undefined;
  "breadcrumbs.catalogue": undefined;
  "breadcrumbs.categories": undefined;
  "breadcrumbs.contentManagement": undefined;
  "breadcrumbs.create": undefined;
  "breadcrumbs.createReception": undefined;
  "breadcrumbs.discounts": undefined;
  "breadcrumbs.edit": undefined;
  "breadcrumbs.editAppointment": undefined;
  "breadcrumbs.editReception": undefined;
  "breadcrumbs.home": undefined;
  "breadcrumbs.credits": undefined;
  "breadcrumbs.creditsActivity": undefined;
  "breadcrumbs.myBookings": undefined;
  "breadcrumbs.newAppointment": undefined;
  "breadcrumbs.operations": undefined;
  "breadcrumbs.payment": undefined;
  "breadcrumbs.receptions": undefined;
  "breadcrumbs.reporting": undefined;
  "breadcrumbs.requestManagement": undefined;
  "breadcrumbs.resources": undefined;
  "breadcrumbs.settings": undefined;
  "breadcrumbs.visitorManagement": undefined;
  "webng.open": undefined;
  "webng.close": undefined;
  "webng.menu": undefined;
  "webng.logout": undefined;
  "webng.webFeedback": undefined;
  "webng.news": undefined;
  "webng.events": undefined;
  "webng.all": undefined;
  "webng.bookings": undefined;
  "webng.requestManagement": undefined;
  "webng.contactUs": undefined;
  "webng.myAccount": undefined;
  "webng.visitorAppointments": undefined;
  "webng.terms": undefined;
  "webng.privacy": undefined;
  "webng.cookieSettings": undefined;
  "webng.getIosApp": undefined;
  "webng.getAndroidApp": undefined;
  "webng.home": undefined;
  "webng.returnToEquiemOne.confirmButtonText": undefined;
  "webng.returnToEquiemOne.confirmMessage": undefined;
  "webng.returnToEquiemOne.confirmTitle": undefined;
  "webng.returnToEquiemOne.tooltip": undefined;
  "common.accountBalance": undefined;
  "common.accept": undefined;
  "common.accessControlGroups": undefined;
  "common.action": undefined;
  "common.activity": undefined;
  "common.add": undefined;
  "common.addAnother": undefined;
  "common.and": undefined;
  "common.addDescription": undefined;
  "common.addFilter": undefined;
  "common.added": undefined;
  "common.address": undefined;
  "common.after": undefined;
  "common.afterHours": undefined;
  "common.all": undefined;
  "common.allow": undefined;
  "common.amount": undefined;
  "common.apartment": undefined;
  "common.applyChanges": undefined;
  "common.areYouSure": undefined;
  "common.area": undefined;
  "common.attachments": undefined;
  "common.audience": undefined;
  "common.back": undefined;
  "common.before": undefined;
  "common.between": undefined;
  "common.billing": undefined;
  "common.billingDelete": undefined;
  "common.body": undefined;
  "common.building": undefined;
  "common.buildings": undefined;
  "common.bulkUpload": undefined;
  "common.cancel": undefined;
  "common.cancelMessage": undefined;
  "common.cancelNo": undefined;
  "common.change": undefined;
  "common.changesSaveFailed": undefined;
  "common.changesSaved": undefined;
  "common.close": undefined;
  "common.comments": undefined;
  "common.company": undefined;
  "common.confirm": undefined;
  "common.contentTooBig": undefined;
  "common.continue": undefined;
  "common.create": undefined;
  "common.createNew": undefined;
  "common.creditCard": undefined;
  "common.credits": undefined;
  "common.customOption": undefined;
  "common.date": undefined;
  "common.days": undefined;
  "common.decline": undefined;
  "common.defaultOption": undefined;
  "common.delete": undefined;
  "common.deleteConfirmationTitle": undefined;
  "common.deleteConfirmationTitleWithTitle": { title: string | number };
  "common.deleteConfirmationWithTitle": { title: string | number };
  "common.deleteConfirmation": { count: number };
  "common.deleteSelected": undefined;
  "common.deleting": undefined;
  "common.description": undefined;
  "common.deselectAll": undefined;
  "common.disable": undefined;
  "common.discard": undefined;
  "common.disabled": undefined;
  "common.done": undefined;
  "common.down": undefined;
  "common.duplicate": undefined;
  "common.edit": undefined;
  "common.email": undefined;
  "common.emailAddress": undefined;
  "common.emails": undefined;
  "common.emptyNone": undefined;
  "common.emptySearch": { searchTerm: string | number };
  "common.enable": undefined;
  "common.enabled": undefined;
  "common.end": undefined;
  "common.endOfList": undefined;
  "common.enterText": undefined;
  "common.exclude": undefined;
  "common.excludes": undefined;
  "common.file": undefined;
  "common.files": undefined;
  "common.filters": undefined;
  "common.findAPerson": undefined;
  "common.firstName": undefined;
  "common.free": undefined;
  "common.formCloseConfirmation": undefined;
  "common.fullDay": undefined;
  "common.fullName": undefined;
  "common.greaterThan": undefined;
  "common.groups": undefined;
  "common.halfDay": undefined;
  "common.hide": undefined;
  "common.hidePrevious": undefined;
  "common.home": undefined;
  "common.host": undefined;
  "common.hour": undefined;
  "common.hourAfterHours": undefined;
  "common.hourWeekend": undefined;
  "common.hours": undefined;
  "common.iAgree": undefined;
  "common.iDisagree": undefined;
  "common.icon": undefined;
  "common.image": undefined;
  "common.includes": undefined;
  "common.incorrectValue": undefined;
  "common.industry": undefined;
  "common.invalidDateFormat": undefined;
  "common.invalidEmailAddress": undefined;
  "common.invalidImage": undefined;
  "common.invalidTerms": undefined;
  "common.invoice": undefined;
  "common.is": undefined;
  "common.isEmpty": undefined;
  "common.isNot": undefined;
  "common.language": undefined;
  "common.lastName": undefined;
  "common.lastUpdated": undefined;
  "common.lessThan": undefined;
  "common.likes": undefined;
  "common.loadMore": undefined;
  "common.loading": undefined;
  "common.location": undefined;
  "common.max": undefined;
  "common.me": undefined;
  "common.message": undefined;
  "common.messageEmpty": undefined;
  "common.min": undefined;
  "common.minutes": undefined;
  "common.missing": undefined;
  "common.mobileNumber": undefined;
  "common.moreDetails": undefined;
  "common.mustBeGreaterThanZero": undefined;
  "common.mustBeNumber": undefined;
  "common.name": undefined;
  "common.nameRequired": undefined;
  "common.next": undefined;
  "common.no": undefined;
  "common.noContent": undefined;
  "common.noResults": undefined;
  "common.noOptions": undefined;
  "common.none": undefined;
  "common.noneAvailable": undefined;
  "common.note": undefined;
  "common.occupants": undefined;
  "common.only": undefined;
  "common.paid": undefined;
  "common.passwordHide": undefined;
  "common.passwordShow": undefined;
  "common.payment": undefined;
  "common.paymentFailed": undefined;
  "common.paymentPending": undefined;
  "common.perItemCost": { price: string | number };
  "common.percentage": undefined;
  "common.permissions": undefined;
  "common.platformPrivacyPolicy": { siteName: string | number };
  "common.platformTermsAndConditions": { siteName: string | number };
  "common.pleaseSelect": undefined;
  "common.plusMore": { count: string | number };
  "common.preview": undefined;
  "common.privacyPolicy": undefined;
  "common.profile": undefined;
  "common.quantity": undefined;
  "common.quickView": undefined;
  "common.refresh": undefined;
  "common.refreshing": undefined;
  "common.refundFailed": undefined;
  "common.refunded": undefined;
  "common.remainingCharacters": { count: string | number };
  "common.remove": undefined;
  "common.required": undefined;
  "common.reset": undefined;
  "common.resultsPerPage": undefined;
  "common.roles": undefined;
  "common.save": undefined;
  "common.saveChanges": undefined;
  "common.saved": undefined;
  "common.saving": undefined;
  "common.scrollDownToLoad": undefined;
  "common.search": undefined;
  "common.searchAttributes": undefined;
  "common.searchToAdd": undefined;
  "common.searchUsers": undefined;
  "common.select": undefined;
  "common.selectAll": undefined;
  "common.selectIcon": undefined;
  "common.selectType": undefined;
  "common.selected": { value: string | number };
  "common.settings": undefined;
  "common.site": undefined;
  "common.someone": undefined;
  "common.somethingWrong": undefined;
  "common.system": undefined;
  "common.squareFeet": undefined;
  "common.squareMeters": undefined;
  "common.status": undefined;
  "common.step": { step: string | number };
  "common.submitted": undefined;
  "common.submitting": undefined;
  "common.summary": undefined;
  "common.sync": undefined;
  "common.takeMeThere": undefined;
  "common.termsAndConditions": undefined;
  "common.termsAndConditionsAndPrivacyPolicy": undefined;
  "common.termsUpdatedBody": undefined;
  "common.termsUpdatedHeader": undefined;
  "common.time": undefined;
  "common.timeAndDate": undefined;
  "common.title": undefined;
  "common.to": undefined;
  "common.toLabel": undefined;
  "common.today": undefined;
  "common.tomorrow": undefined;
  "common.total": undefined;
  "common.undo": undefined;
  "common.unit": undefined;
  "common.unknown": undefined;
  "common.unknownError": undefined;
  "common.unknownErrorMessage": undefined;
  "common.unknownErrorPleaseTryAgain": undefined;
  "common.pleaseUpgradeMobileApp": undefined;
  "common.up": undefined;
  "common.upload": undefined;
  "common.uploadError": undefined;
  "common.uploadFile": undefined;
  "common.uploadFiles": undefined;
  "common.uploaded": undefined;
  "common.url": undefined;
  "common.user": undefined;
  "common.users": undefined;
  "common.validation.arrayMax": { path: string | number, max: string | number };
  "common.validation.arrayMin": { path: string | number, min: string | number };
  "common.validation.dateMax": { path: string | number, max: string | number };
  "common.validation.dateMin": { path: string | number, min: string | number };
  "common.validation.default": { path: string | number };
  "common.validation.email": { path: string | number };
  "common.validation.exactValue": { path: string | number, value: string | number };
  "common.validation.notOneOf": { path: string | number, values: string | number };
  "common.validation.numberMax": { path: string | number, max: string | number };
  "common.validation.numberMin": { path: string | number, min: string | number };
  "common.validation.oneOf": { path: string | number, values: string | number };
  "common.validation.required": { path: string | number };
  "common.validation.stringMax": { path: string | number, max: string | number };
  "common.validation.stringMin": { path: string | number, min: string | number };
  "common.viewPrevious": undefined;
  "common.weekend": undefined;
  "common.workplace": undefined;
  "common.xOfYResults": { x: string | number, y: string | number };
  "common.yes": undefined;
  "common.yesArchive": undefined;
  "common.yesCancel": undefined;
  "common.yesClose": undefined;
  "common.yesDelete": undefined;
  "common.yesRemove": undefined;
  "common.yesRestore": undefined;
  "roomPanels.common.available": undefined;
  "roomPanels.common.next": undefined;
  "roomPanels.common.busy": undefined;
  "roomPanels.common.now": undefined;
  "roomPanels.common.features": undefined;
  "roomPanels.common.host": undefined;
  "contentManagement.articles.allArticles": undefined;
  "contentManagement.articles.bottomBar.archive": undefined;
  "contentManagement.articles.bottomBar.archiveDescription": undefined;
  "contentManagement.articles.bottomBar.archiveSuccess": undefined;
  "contentManagement.articles.bottomBar.changePublishSettings": undefined;
  "contentManagement.articles.bottomBar.changeSites": undefined;
  "contentManagement.articles.bottomBar.prepareForPublishing": undefined;
  "contentManagement.articles.bottomBar.publishChanges": undefined;
  "contentManagement.articles.bottomBar.publishChangesConfirm": undefined;
  "contentManagement.articles.bottomBar.restore": undefined;
  "contentManagement.articles.bottomBar.restoreDescription": undefined;
  "contentManagement.articles.bottomBar.restoreSuccess": undefined;
  "contentManagement.articles.bottomBar.revertDraft": undefined;
  "contentManagement.articles.bottomBar.revertDraftConfirm": undefined;
  "contentManagement.articles.bottomBar.revertDraftConfirmation": undefined;
  "contentManagement.articles.bottomBar.revertDraftSuccess": undefined;
  "contentManagement.articles.bottomBar.saveDraft": undefined;
  "contentManagement.articles.bottomBar.saveDraftSuccess": undefined;
  "contentManagement.articles.bottomBar.unpublish": undefined;
  "contentManagement.articles.bottomBar.unpublishConfirm": undefined;
  "contentManagement.articles.bottomBar.unpublishConfirmation": undefined;
  "contentManagement.articles.bottomBar.unpublishSuccess": undefined;
  "contentManagement.articles.defaultTitle": undefined;
  "contentManagement.articles.editArticle": undefined;
  "contentManagement.articles.form.audience": undefined;
  "contentManagement.articles.form.audienceTooltip": undefined;
  "contentManagement.articles.form.author": undefined;
  "contentManagement.articles.form.authorCustom": undefined;
  "contentManagement.articles.form.authorCustomPlaceholder": undefined;
  "contentManagement.articles.form.authorCustomSubtext": undefined;
  "contentManagement.articles.form.authorCustomTitle": undefined;
  "contentManagement.articles.form.authorCustomTooltip": undefined;
  "contentManagement.articles.form.authorDefaultSubtext": undefined;
  "contentManagement.articles.form.authorDefaultTitle": undefined;
  "contentManagement.articles.form.authorPreviewTooltip": undefined;
  "contentManagement.articles.form.authorTooltip": undefined;
  "contentManagement.articles.form.body": undefined;
  "contentManagement.articles.form.customAuthorTooltip": { email: string | number };
  "contentManagement.articles.form.defaultAuthorTooltip": { email: string | number };
  "contentManagement.articles.form.excerpt": undefined;
  "contentManagement.articles.form.excerptPlaceholder": undefined;
  "contentManagement.articles.form.files": undefined;
  "contentManagement.articles.form.filesTooltip": undefined;
  "contentManagement.articles.form.images": undefined;
  "contentManagement.articles.form.publishLabel": undefined;
  "contentManagement.articles.form.publishLaterSubtext": undefined;
  "contentManagement.articles.form.publishLaterTitle": undefined;
  "contentManagement.articles.form.publishNowSubtext": undefined;
  "contentManagement.articles.form.publishNowTitle": undefined;
  "contentManagement.articles.form.publishNowTitleShort": undefined;
  "contentManagement.articles.form.publishTooltip": undefined;
  "contentManagement.articles.form.scheduleDate": undefined;
  "contentManagement.articles.form.scheduleDateTooltip": undefined;
  "contentManagement.articles.form.scheduleTime": undefined;
  "contentManagement.articles.form.scheduleTimeTooltip": undefined;
  "contentManagement.articles.form.searchSites": undefined;
  "contentManagement.articles.form.siteSettings": undefined;
  "contentManagement.articles.form.siteSettingsTooltip": undefined;
  "contentManagement.articles.form.sites": undefined;
  "contentManagement.articles.form.sitesRequired": undefined;
  "contentManagement.articles.form.title": undefined;
  "contentManagement.articles.form.titlePlaceholder": undefined;
  "contentManagement.articles.modals.publishSettingsIntro": undefined;
  "contentManagement.articles.modals.publishSettingsPublishSuccess": undefined;
  "contentManagement.articles.modals.publishSettingsScheduleSuccess": undefined;
  "contentManagement.articles.modals.publishSettingsTitle": undefined;
  "contentManagement.articles.modals.publishSettingsUpdateSuccess": undefined;
  "contentManagement.articles.modals.publishingIntro": undefined;
  "contentManagement.articles.modals.publishingSuccess": undefined;
  "contentManagement.articles.modals.publishingTitle": undefined;
  "contentManagement.articles.modals.saveChanges": undefined;
  "contentManagement.articles.modals.sitesIntro": undefined;
  "contentManagement.articles.modals.sitesNextButton": undefined;
  "contentManagement.articles.modals.sitesSuccess": undefined;
  "contentManagement.articles.modals.sitesTitle": undefined;
  "contentManagement.articles.modals.updatingSuccess": undefined;
  "contentManagement.articles.newArticle": undefined;
  "contentManagement.articles.noPublishDate": undefined;
  "contentManagement.deleteFileAttachment": undefined;
  "contentManagement.deleteFileAttachmentMessage": undefined;
  "contentManagement.newVersion": undefined;
  "contentManagement.noPortfolio": undefined;
  "contentManagement.noPortfoliosDescription": undefined;
  "contentManagement.noPortfoliosTitle": undefined;
  "contentManagement.preview": undefined;
  "contentManagement.publishedVersion": undefined;
  "contentManagement.savedTime": { ago: string | number };
  "contentManagement.statuses.archived": undefined;
  "contentManagement.statuses.draft": undefined;
  "contentManagement.statuses.draftDifferentToPublished": undefined;
  "contentManagement.statuses.published": undefined;
  "equiem-one-ui.creditcard.addCard": undefined;
  "equiem-one-ui.creditcard.addDescription": undefined;
  "equiem-one-ui.creditcard.addNewCard": undefined;
  "equiem-one-ui.creditcard.cardInfoNotFound": undefined;
  "equiem-one-ui.creditcard.cardDetailsExist": undefined;
  "equiem-one-ui.creditcard.cardDetailsExistandDefault": undefined;
  "equiem-one-ui.creditcard.cardSaved": undefined;
  "equiem-one-ui.creditcard.cardSavedAndDefault": undefined;
  "equiem-one-ui.creditcard.stripeElementIsNotInitialised": undefined;
  "equiem-one-ui.creditcard.stripeProviderNotLoaded": undefined;
  "equiem-one-ui.creditcard.tokenisationFailed": undefined;
  "equiem-one-ui.creditcard.untitled": undefined;
  "equiem-one-ui.common.noResultsState": undefined;
  "flex.memberStatus.active": undefined;
  "flex.memberStatus.expired": undefined;
  "flex.memberStatus.expiring": { days: string | number };
  "flex.memberStatus.pending": undefined;
  "flex.memberships.removeTitle": undefined;
  "flex.memberships.removeBody": undefined;
  "flex.members.addButtonTitle": undefined;
  "flex.members.editMembership": undefined;
  "flex.members.filters.sites": undefined;
  "flex.members.filters.buildings": undefined;
  "flex.members.editForm.titles.membership": undefined;
  "flex.members.editForm.titles.buildings": undefined;
  "flex.members.editForm.titles.company": undefined;
  "flex.members.form.buildings": undefined;
  "flex.members.form.buildingPlaceholder": undefined;
  "flex.members.form.addBuildingButton": undefined;
  "flex.members.form.company": undefined;
  "flex.members.form.membershipName": undefined;
  "flex.members.form.creditBalance": undefined;
  "flex.members.form.creditAllowance": undefined;
  "flex.members.form.creditAllowanceMonthly": undefined;
  "flex.members.form.expirationDate": undefined;
  "flex.members.form.levels": undefined;
  "flex.members.form.location": undefined;
  "flex.members.form.locations": undefined;
  "flex.members.form.locationsHelpText": undefined;
  "flex.members.form.moveInDate": undefined;
  "flex.members.form.status": undefined;
  "flex.members.form.teamSize": undefined;
  "flex.members.form.teamSizeAll": undefined;
  "flex.members.form.teamSizeLimited": undefined;
  "flex.members.form.generalInformation": undefined;
  "flex.members.form.workplaceManager": undefined;
  "flex.members.form.address": undefined;
  "flex.members.form.address2": undefined;
  "flex.members.form.country": undefined;
  "flex.members.form.postalCode": undefined;
  "flex.members.form.city": undefined;
  "flex.members.form.state": undefined;
  "flex.members.form.companyBusinessName": undefined;
  "flex.members.form.contactEmail": undefined;
  "flex.members.form.contactInformation": undefined;
  "flex.members.form.billingInformation": undefined;
  "flex.members.form.titles.addMembership": undefined;
  "flex.members.form.titles.addBuildings": undefined;
  "flex.members.form.titles.addCompany": undefined;
  "flex.members.title": undefined;
  "flex.role": undefined;
  "flex.shortcuts.credits": undefined;
  "flex.shortcuts.members": undefined;
  "flex.shortcuts.memberships": undefined;
  "flex.shortcuts.title": undefined;
  "flex.title": undefined;
  "home.allLearning": undefined;
  "home.allNotificationsCleared": undefined;
  "home.areYouSureSwitch": undefined;
  "home.clearAllNotifications": undefined;
  "home.contactAdministrator": undefined;
  "home.cookiePreference.acceptAllCookies": undefined;
  "home.cookiePreference.acceptCookies": undefined;
  "home.cookiePreference.acceptNecessaryCookies": undefined;
  "home.cookiePreference.cookiePolicy": undefined;
  "home.cookiePreference.cookiePreferences": undefined;
  "home.cookiePreference.forMoreInformationSee": undefined;
  "home.cookiePreference.managePreferences": undefined;
  "home.cookiePreference.optionalCookies": undefined;
  "home.cookiePreference.strictlyNecessaryCookies": undefined;
  "home.cookiePreference.theseCookiesAreRequired": undefined;
  "home.cookiePreference.theseCookiesUsedTo": undefined;
  "home.cookiePreference.thisSiteUsesCookies": undefined;
  "home.cookiePreference.weUseCookies": undefined;
  "home.cookiePreference.weUseCookiesToImprove": undefined;
  "home.equiemOneLearning": undefined;
  "home.equiemSupport": undefined;
  "home.feedback": undefined;
  "home.findSite": undefined;
  "home.greetingAM": { name: string | number };
  "home.greetingPM": { name: string | number };
  "home.helpSupport": undefined;
  "home.hideTheSideMenu": undefined;
  "home.insightsTitle": undefined;
  "home.insightsTitleSite": undefined;
  "home.insightsTitleVisitors": undefined;
  "home.insightsTitleWorkplace": undefined;
  "home.learning": undefined;
  "home.loadingNotifications": undefined;
  "home.logOut": undefined;
  "home.manageAccount": undefined;
  "home.noAccess": undefined;
  "home.noResultsFoundFor": { filterText: string | number };
  "home.notificationRemoved": undefined;
  "home.notifications": undefined;
  "home.platformStatus": undefined;
  "home.roleMightBeInferred": undefined;
  "home.shortcuts.addYourOwnShortcut": undefined;
  "home.shortcuts.appointments": undefined;
  "home.shortcuts.collapseShortcuts": undefined;
  "home.shortcuts.createEventPost": undefined;
  "home.shortcuts.createNewsPost": undefined;
  "home.shortcuts.createShortcut": undefined;
  "home.shortcuts.deleteShortcut": undefined;
  "home.shortcuts.faq": undefined;
  "home.shortcuts.followTheBelowSteps": undefined;
  "home.shortcuts.goTo": { siteName: string | number };
  "home.shortcuts.helpCentre": undefined;
  "home.shortcuts.inviteUsers": { siteName: string | number };
  "home.shortcuts.linkTitle": undefined;
  "home.shortcuts.linkUrl": undefined;
  "home.shortcuts.myShortcuts": undefined;
  "home.shortcuts.sendEmailNotification": undefined;
  "home.shortcuts.sendNewsletter": undefined;
  "home.shortcuts.sendSinglePostNotification": undefined;
  "home.shortcuts.support": undefined;
  "home.shortcuts.theHub": undefined;
  "home.shortcuts.title": undefined;
  "home.shortcuts.toLinkPage": undefined;
  "home.shortcuts.visitorManagement": undefined;
  "home.showTheSideMenu": undefined;
  "home.sideMenu.areYouSureYouWantDelete": { title: string | number };
  "home.sideMenu.credits": undefined;
  "home.sideMenu.bookings": undefined;
  "home.sideMenu.contentManagement": undefined;
  "home.sideMenu.deleteShortcut": undefined;
  "home.sideMenu.goToStoreManagement": undefined;
  "home.sideMenu.home": undefined;
  "home.sideMenu.insights": undefined;
  "home.sideMenu.reporting": undefined;
  "home.sideMenu.requestManagement": undefined;
  "home.sideMenu.requestManagementShort": undefined;
  "home.sideMenu.usersSiteSettings": undefined;
  "home.sideMenu.vendorManagement": undefined;
  "home.sideMenu.yourShortcutHasBeenSuccessfullyDeleted": undefined;
  "home.switchRole": undefined;
  "home.switchSite": undefined;
  "home.switchSiteRole": undefined;
  "home.switchSites": undefined;
  "home.theHub": undefined;
  "home.widgets.activeUsers": undefined;
  "home.widgets.activeUsersLastDays": { duration: string | number };
  "home.widgets.activeUsersMetric": undefined;
  "home.widgets.activityHistory": undefined;
  "home.widgets.addGroups": undefined;
  "home.widgets.addNewUsers": undefined;
  "home.widgets.addRoles": undefined;
  "home.widgets.allCompanies": undefined;
  "home.widgets.allViews": undefined;
  "home.widgets.alreadyCheckedIn": undefined;
  "home.widgets.attendingLimited": { value: string | number, limit: string | number };
  "home.widgets.attendingUnlimited": { value: string | number };
  "home.widgets.buildingHint": { siteName: string | number };
  "home.widgets.buildingInfoPost": undefined;
  "home.widgets.buildingInfoPostHint": undefined;
  "home.widgets.bulkUserUpload": undefined;
  "home.widgets.cancelNo": undefined;
  "home.widgets.capacityNumberOfTickets": undefined;
  "home.widgets.checkIn": undefined;
  "home.widgets.checkInIntro": { title: string | number };
  "home.widgets.colleaguesGoing": { count: number };
  "home.widgets.comingSoon": undefined;
  "home.widgets.comingSoonIntro": undefined;
  "home.widgets.contentHistoryTitle": undefined;
  "home.widgets.contentHistoryTooltip": undefined;
  "home.widgets.contentType": undefined;
  "home.widgets.copyAndPasteEmailAddresses": undefined;
  "home.widgets.copyAndPasteEmailAddressesTooltip": undefined;
  "home.widgets.copyLink": undefined;
  "home.widgets.createEventPost": undefined;
  "home.widgets.createNew": undefined;
  "home.widgets.createNewsPost": undefined;
  "home.widgets.deactivations": undefined;
  "home.widgets.deletePost": undefined;
  "home.widgets.deletePostConfirm": { title: string | number };
  "home.widgets.draft": undefined;
  "home.widgets.editEvent": undefined;
  "home.widgets.editPost": undefined;
  "home.widgets.emailAddresses": undefined;
  "home.widgets.emailAddressesBulk": undefined;
  "home.widgets.emailAddressesHint": undefined;
  "home.widgets.emailAddressesInfo": undefined;
  "home.widgets.emailClickRate": undefined;
  "home.widgets.emailClickRateLastDays": { duration: string | number };
  "home.widgets.emailClickRateMetric": undefined;
  "home.widgets.emailOpenRate": undefined;
  "home.widgets.emailOpenRateLastDays": { duration: string | number };
  "home.widgets.emailOpenRateMetric": undefined;
  "home.widgets.emailOpenRateOfficeMetric": undefined;
  "home.widgets.emailSentDate": { date: string | number };
  "home.widgets.emptyContentHistory": undefined;
  "home.widgets.emptyContentHistoryDetails": undefined;
  "home.widgets.emptyInvitesList": undefined;
  "home.widgets.event": undefined;
  "home.widgets.eventCheckIn": undefined;
  "home.widgets.eventHint": undefined;
  "home.widgets.eventPost": undefined;
  "home.widgets.eventRsvp": undefined;
  "home.widgets.eventRsvpLastDays": { duration: string | number };
  "home.widgets.eventRsvpMetric": undefined;
  "home.widgets.existingEmails": undefined;
  "home.widgets.exportToCsv": undefined;
  "home.widgets.fileLimit": undefined;
  "home.widgets.finishDateAndTime": undefined;
  "home.widgets.goToReporting": undefined;
  "home.widgets.introduction": undefined;
  "home.widgets.introductionHint": undefined;
  "home.widgets.invalidEmail": undefined;
  "home.widgets.invalidEmails": undefined;
  "home.widgets.inviteAll": undefined;
  "home.widgets.inviteApproveRegistration": undefined;
  "home.widgets.inviteApproveRegistrationError": undefined;
  "home.widgets.inviteApproveRegistrationLoading": undefined;
  "home.widgets.inviteApproveRegistrationSuccess": { email: string | number };
  "home.widgets.inviteCancelModal": undefined;
  "home.widgets.inviteError": undefined;
  "home.widgets.inviteHasError": undefined;
  "home.widgets.inviteModalTitle": undefined;
  "home.widgets.invitePending": undefined;
  "home.widgets.inviteResendError": undefined;
  "home.widgets.inviteResendLoading": undefined;
  "home.widgets.inviteResendSuccess": undefined;
  "home.widgets.inviteResponded": undefined;
  "home.widgets.inviteRoleTooltip": undefined;
  "home.widgets.inviteFlexTenantTooltip": undefined;
  "home.widgets.inviteSuccess": { count: number };
  "home.widgets.inviteUserAdd": undefined;
  "home.widgets.inviteUsers": undefined;
  "home.widgets.invited": undefined;
  "home.widgets.inviting": undefined;
  "home.widgets.issiesWithUsers": undefined;
  "home.widgets.lastDays": { duration: string | number };
  "home.widgets.leastActiveCompanyLastDays": { duration: string | number };
  "home.widgets.leastActiveTenant": undefined;
  "home.widgets.linkCopiedToClipboard": undefined;
  "home.widgets.location": undefined;
  "home.widgets.locationAddress": undefined;
  "home.widgets.locationAddressHint": undefined;
  "home.widgets.locationName": undefined;
  "home.widgets.locationNameHint": undefined;
  "home.widgets.metricsFilterTooltip": undefined;
  "home.widgets.mostActiveCompanyLastDays": { duration: string | number };
  "home.widgets.mostActiveTenant": undefined;
  "home.widgets.mostActiveTenantHint": undefined;
  "home.widgets.newEvent": undefined;
  "home.widgets.newPost": undefined;
  "home.widgets.newRegistrations": undefined;
  "home.widgets.news": undefined;
  "home.widgets.newsPost": undefined;
  "home.widgets.nextGuest": undefined;
  "home.widgets.noCompaniesFound": undefined;
  "home.widgets.noTrendingNews": undefined;
  "home.widgets.noUpcomingEvents": undefined;
  "home.widgets.officeEngagement": undefined;
  "home.widgets.openInNewTab": undefined;
  "home.widgets.postDeletedSuccess": undefined;
  "home.widgets.postEditSavedAsDraft": undefined;
  "home.widgets.postEditSavedPublished": undefined;
  "home.widgets.postEditSavedSubmit": undefined;
  "home.widgets.postHint": undefined;
  "home.widgets.postInApprovalQueue": undefined;
  "home.widgets.postInDraft": undefined;
  "home.widgets.postInPublished": undefined;
  "home.widgets.postSavedAsDraft": undefined;
  "home.widgets.postSavedPublished": undefined;
  "home.widgets.postSavedSubmit": undefined;
  "home.widgets.postTitle": undefined;
  "home.widgets.postTitleHint": undefined;
  "home.widgets.postWorkplaceHint": undefined;
  "home.widgets.postWorkplaceHist": undefined;
  "home.widgets.preferred": undefined;
  "home.widgets.publicationDate": undefined;
  "home.widgets.publicationDateTime": undefined;
  "home.widgets.published": undefined;
  "home.widgets.purchasedOnlyEvent": undefined;
  "home.widgets.registeredUsers": undefined;
  "home.widgets.registeredUsersLastDays": { duration: string | number };
  "home.widgets.registeredUsersMetric": undefined;
  "home.widgets.registeredUsersOfficeMetric": undefined;
  "home.widgets.registrations": undefined;
  "home.widgets.relatedInformation": undefined;
  "home.widgets.removeImage": undefined;
  "home.widgets.reporting": undefined;
  "home.widgets.resendInvitation": undefined;
  "home.widgets.resentInvitation": undefined;
  "home.widgets.review": undefined;
  "home.widgets.saveAndPublish": undefined;
  "home.widgets.saveAndSubmit": undefined;
  "home.widgets.saveDraft": undefined;
  "home.widgets.selectCompany": undefined;
  "home.widgets.selectFlexTenant": undefined;
  "home.widgets.shareEmailEventTitle": { title: string | number };
  "home.widgets.shareEmailTitle": { title: string | number };
  "home.widgets.shareViaEmail": undefined;
  "home.widgets.siteCommunity": undefined;
  "home.widgets.siteCommunityHint": { siteName: string | number };
  "home.widgets.siteEngagement": undefined;
  "home.widgets.siteEngagementTooltip": undefined;
  "home.widgets.sorryEventFull": undefined;
  "home.widgets.sorryEventFullUrl": { url: string | number };
  "home.widgets.startDateAndTime": undefined;
  "home.widgets.termsAndConditionsEventsIntro": undefined;
  "home.widgets.termsAndConditionsIntro": undefined;
  "home.widgets.thankYouUser": { firstName: string | number };
  "home.widgets.thanksCheckIn": { firstName: string | number };
  "home.widgets.theFollowingUsersHaveAlreadyInvited": undefined;
  "home.widgets.theFollowingUsersHaveAlreadyRegistered": undefined;
  "home.widgets.trendFromPreviousPeriod": undefined;
  "home.widgets.trendingContentPropertyTooltip": undefined;
  "home.widgets.trendingContentWorkplaceTooltip": undefined;
  "home.widgets.trendingNews": undefined;
  "home.widgets.trendingNewsAt": { location: string | number };
  "home.widgets.upcomingEvents": undefined;
  "home.widgets.upcomingEventsPropertyTooltip": undefined;
  "home.widgets.upcomingEventsWorkplaceTooltip": undefined;
  "home.widgets.updatePost": undefined;
  "home.widgets.userPermissions": undefined;
  "home.widgets.userRegistrationIntro": undefined;
  "home.widgets.usersPending": undefined;
  "home.widgets.usersPendingApproval": undefined;
  "home.widgets.usersRegistered": undefined;
  "home.widgets.usersWereSuccessfullyInvited": { count: number };
  "home.widgets.validationCompany": undefined;
  "home.widgets.validationFirstName": undefined;
  "home.widgets.validationLastName": undefined;
  "home.widgets.validationWorkEmail": undefined;
  "home.widgets.viewOnSite": undefined;
  "home.widgets.viewRoadmap": undefined;
  "home.widgets.viewRsvpReport": undefined;
  "home.widgets.welcomeAssignCompany": undefined;
  "home.widgets.welcomeAssignGroups": undefined;
  "home.widgets.welcomeAssignRoles": undefined;
  "home.widgets.welcomeDeleteConfirm": { total: string | number, entries: string | number };
  "home.widgets.welcomeDeleteConfirmEntries": undefined;
  "home.widgets.welcomeDeleteConfirmEntry": undefined;
  "home.widgets.welcomeEmailAlreadyEntered": undefined;
  "home.widgets.welcomeHelpText": { siteName: string | number };
  "home.widgets.welcomeNoInviteHistoryText": undefined;
  "home.widgets.welcomeWidgetTitle": undefined;
  "home.widgets.welconeWidgetAction": undefined;
  "home.widgets.whoYouLikeToInvite": undefined;
  "home.widgets.workEmailAddress": undefined;
  "home.widgets.workEmailAddressPlaceholder": undefined;
  "home.yesSwitch": undefined;
  "home.youDontHavePermission": undefined;
  "home.shouldHaveAccessHelp": undefined;
  "home.youHaveNoNotifications": undefined;
  "home.youHaveNoRoles": undefined;
  "home.youSureYouLikeToSwitchSite": undefined;
  "homepage.articleNotFound": undefined;
  "homepage.logIn": undefined;
  "homepage.newsAndUpdates": undefined;
  "homepage.noArticlesFound": undefined;
  "homepage.register": undefined;
  "homepage.relatedInformation": undefined;
  "homepage.welcomeHeadingFallback": undefined;
  "homepage.welcomeSubHeadingFallback": undefined;
  "requests.activity.assigneeChanged": undefined;
  "requests.activity.attachmentAdded": { name: string | number };
  "requests.activity.attachmentRemoved": { name: string | number };
  "requests.activity.categoryChanged": undefined;
  "requests.activity.dateCompletedChanged": undefined;
  "requests.activity.dateReportedChanged": undefined;
  "requests.activity.descriptionChanged": undefined;
  "requests.activity.everyone": undefined;
  "requests.activity.internalTeam": undefined;
  "requests.activity.locationChanged": undefined;
  "requests.activity.publicMessageConfirm": undefined;
  "requests.activity.publicMessageConfirmation": undefined;
  "requests.activity.publicMessageConfirmationRM": undefined;
  "requests.activity.reporterChanged": undefined;
  "requests.activity.requestClosed": undefined;
  "requests.activity.requestOpened": undefined;
  "requests.activity.statusChanged": undefined;
  "requests.activity.subCategoryAdded": undefined;
  "requests.activity.subCategoryChanged": undefined;
  "requests.activity.subCategoryRemoved": undefined;
  "requests.activity.toEveryone": undefined;
  "requests.activity.toInternalTeam": undefined;
  "requests.activity.watcherAdded": undefined;
  "requests.activity.watcherRemoved": undefined;
  "requests.allRequests": undefined;
  "requests.assignee": undefined;
  "requests.attachments.incorrectFileSize": undefined;
  "requests.attachments.incorrectUploadedFile": undefined;
  "requests.attachments.validationRule": undefined;
  "requests.category.addCategory": undefined;
  "requests.category.addNewCategory": undefined;
  "requests.category.addSubcategory": undefined;
  "requests.category.alreadyExists": undefined;
  "requests.category.buildingsSearchPlaceholder": undefined;
  "requests.category.categories": undefined;
  "requests.category.categoryName": undefined;
  "requests.category.categoryTypeLabel": undefined;
  "requests.category.categoryTypePlaceholder": undefined;
  "requests.category.configureCategories": undefined;
  "requests.category.deleteCategorySuccess": { categoryName: string | number };
  "requests.category.deleteSubCategorySuccess": { subCategoryName: string | number };
  "requests.category.description": undefined;
  "requests.category.edit": undefined;
  "requests.category.newCategoryAdded": undefined;
  "requests.category.newSubCategoryAdded": undefined;
  "requests.category.noCategoryHasBeenAdded": undefined;
  "requests.category.noSubCategoryHasBeenAdded": undefined;
  "requests.category.pleaseSelectACategory": undefined;
  "requests.category.subCategoryDescription": undefined;
  "requests.category.subcategories": undefined;
  "requests.category.types.accessSecurity": undefined;
  "requests.category.types.amenities": undefined;
  "requests.category.types.buildingMaintenance": undefined;
  "requests.category.types.buildingManagement": undefined;
  "requests.category.types.cctv": undefined;
  "requests.category.types.cleaning": undefined;
  "requests.category.types.customerService": undefined;
  "requests.category.types.electrical": undefined;
  "requests.category.types.escalators": undefined;
  "requests.category.types.fabric": undefined;
  "requests.category.types.fireSafety": undefined;
  "requests.category.types.furnitureEquipment": undefined;
  "requests.category.types.generalMaintenance": undefined;
  "requests.category.types.healthSafety": undefined;
  "requests.category.types.heatingCooling": undefined;
  "requests.category.types.itSystems": undefined;
  "requests.category.types.landscaping": undefined;
  "requests.category.types.lifts": undefined;
  "requests.category.types.lighting": undefined;
  "requests.category.types.mechanicalElectrical": undefined;
  "requests.category.types.miscellaneousOther": undefined;
  "requests.category.types.parking": undefined;
  "requests.category.types.pestControl": undefined;
  "requests.category.types.plants": undefined;
  "requests.category.types.plumbingDrainage": undefined;
  "requests.category.types.toilets": undefined;
  "requests.category.types.wasteRecycling": undefined;
  "requests.category.types.waterManagement": undefined;
  "requests.category.integrations.cafmProvider": undefined;
  "requests.category.integrations.providerCategoryName": undefined;
  "requests.category.integrations.add": undefined;
  "requests.category.integrations.edit": undefined;
  "requests.category.integrations.addIntegration": undefined;
  "requests.category.integrations.editIntegration": undefined;
  "requests.category.integrations.confirmDel": undefined;
  "requests.category.integrations.delSucceseful": undefined;
  "requests.category.integrations.updateSucceseful": undefined;
  "requests.category.integrations.createSucceseful": undefined;
  "requests.create.category": undefined;
  "requests.create.deleteNewRequest": undefined;
  "requests.create.deleteNewRequestMessage": undefined;
  "requests.create.details": undefined;
  "requests.create.error": undefined;
  "requests.create.location": undefined;
  "requests.create.newRequest": undefined;
  "requests.create.requestCreatedMessage": { reference: string | number };
  "requests.create.requestSubmitted": undefined;
  "requests.create.reviewRequest": undefined;
  "requests.create.reviewYourRequest": undefined;
  "requests.create.searchLevel": undefined;
  "requests.create.selectBuilding": undefined;
  "requests.create.selectCategory": undefined;
  "requests.create.selectLevel": undefined;
  "requests.create.selectSpace": undefined;
  "requests.create.submitRequest": undefined;
  "requests.csv.dateTimeCreated": undefined;
  "requests.csv.closedDateTime": undefined;
  "requests.csv.reporter": undefined;
  "requests.csv.reporterCompany": undefined;
  "requests.csv.id": undefined;
  "requests.csv.status": undefined;
  "requests.csv.building": undefined;
  "requests.csv.level": undefined;
  "requests.csv.space": undefined;
  "requests.csv.category": undefined;
  "requests.csv.subCategory": undefined;
  "requests.csv.assignee": undefined;
  "requests.csv.description": undefined;
  "requests.csv.attachments": undefined;
  "requests.csv.watchers": undefined;
  "requests.csv.lastEditedDateTime": undefined;
  "requests.csv.lastEditedBy": undefined;
  "requests.csv.activityAndComments": undefined;
  "requests.details.assignee": undefined;
  "requests.details.building": undefined;
  "requests.details.category": undefined;
  "requests.details.categoryAndSubcategory": undefined;
  "requests.details.retryCafm": undefined;
  "requests.details.retryCafmText": undefined;
  "requests.details.dateCompleted": undefined;
  "requests.details.dateReported": undefined;
  "requests.details.editRequest": { reference: string | number };
  "requests.details.level": undefined;
  "requests.details.location": undefined;
  "requests.details.noSubcategory": undefined;
  "requests.details.requestTitle": { reference: string | number };
  "requests.details.space": undefined;
  "requests.details.spaceIsRequired": undefined;
  "requests.details.status": undefined;
  "requests.details.subcategory": undefined;
  "requests.details.unwatch": undefined;
  "requests.details.viewActivities": undefined;
  "requests.details.watch": undefined;
  "requests.details.watcher": { count: number };
  "requests.details.watchers": undefined;
  "requests.icon": undefined;
  "requests.list.category": undefined;
  "requests.list.checkingRoles": undefined;
  "requests.list.checkingAccessToBuilding": undefined;
  "requests.list.loadingStatuses": undefined;
  "requests.list.loadingScope": undefined;
  "requests.list.location": undefined;
  "requests.list.noResult": undefined;
  "requests.list.created": undefined;
  "requests.list.reportedBy": undefined;
  "requests.list.request": undefined;
  "requests.list.scrollDownToLoad": undefined;
  "requests.list.status": undefined;
  "requests.list.watching": undefined;
  "requests.list.monthAgo": undefined;
  "requests.list.monthsAgo": { num: string | number };
  "requests.list.dayAgo": undefined;
  "requests.list.daysAgo": { num: string | number };
  "requests.list.hourAgo": undefined;
  "requests.list.hoursAgo": { num: string | number };
  "requests.list.hoursAndMinutesAgo": { hours: string | number, minutes: string | number };
  "requests.list.minutesAgo": { num: string | number };
  "requests.openRequest": undefined;
  "requests.reporter": undefined;
  "requests.status.header": undefined;
  "requests.status.addStatus": undefined;
  "requests.status.updateStatus": undefined;
  "requests.status.configureStatuses": undefined;
  "requests.status.noStatusHasBeenAdded": undefined;
  "requests.status.statusAdded": undefined;
  "requests.status.statusUpdated": undefined;
  "requests.status.pleaseSelectAStatusType": undefined;
  "requests.status.statusDeleted": undefined;
  "requests.status.statusName": undefined;
  "requests.status.statusNamePlaceholder": undefined;
  "requests.status.statusType": undefined;
  "requests.status.statuses": undefined;
  "requests.status.owner": undefined;
  "requests.status.ownerPlaceholder": undefined;
  "requests.status.type.COMPLETED": undefined;
  "requests.status.type.IN_PROGRESS": undefined;
  "requests.status.type.NOT_STARTED": undefined;
  "requests.tab.all": undefined;
  "requests.tab.allCompanyRequests": { company: string | number };
  "requests.tab.allRequests": undefined;
  "requests.tab.assignedToMe": undefined;
  "requests.tab.assignedRequests": undefined;
  "requests.tab.myRequests": undefined;
  "requests.tab.watching": undefined;
  "requests.tab.completed": undefined;
  "requests.thereAreNoResultsAvailable": { query: string | number };
  "requests.titleLong": undefined;
  "requests.titleShort": undefined;
  "requests.unassigned": undefined;
  "requests.open": undefined;
  "requests.widgets.activeRequests": undefined;
  "requests.widgets.activeRequestsTooltip": undefined;
  "requests.widgets.allBuildings": undefined;
  "requests.widgets.allCompanies": undefined;
  "requests.widgets.averageResolutionTime": undefined;
  "requests.widgets.averageResolutionTimeTooltip": undefined;
  "requests.widgets.completedRequests": undefined;
  "requests.widgets.completedRequestsTooltip": undefined;
  "requests.widgets.filterTooltip": undefined;
  "requests.widgets.insights": undefined;
  "requests.widgets.insightsTooltip": undefined;
  "requests.widgets.insightsTooltipPM": undefined;
  "requests.widgets.newRequests": undefined;
  "requests.widgets.newRequestsTooltip": undefined;
  "requests.widgets.unassignedRequests": undefined;
  "requests.widgets.unassignedRequestsTooltip": undefined;
  "requests.widgets.viewUnassigned": undefined;
  "segmentation.addFilter": undefined;
  "segmentation.addSegment": undefined;
  "segmentation.audienceTooltip": undefined;
  "segmentation.audienceTooltipEmail": undefined;
  "segmentation.audienceTooltipPushNotification": undefined;
  "segmentation.audienceTooltipSms": undefined;
  "segmentation.changeSummary.filterAdded": { filterType: string | number, items: string | number };
  "segmentation.changeSummary.filterDisabled": { filterType: string | number };
  "segmentation.changeSummary.filterEnabled": { filterType: string | number };
  "segmentation.changeSummary.filterRemoved": { filterType: string | number, items: string | number };
  "segmentation.deleteFilter": undefined;
  "segmentation.deleteFilterConfirmation": undefined;
  "segmentation.deleteFilterMessage": undefined;
  "segmentation.deleteSegment": undefined;
  "segmentation.deleteSegmentConfirmation": undefined;
  "segmentation.deleteSegmentMessage": undefined;
  "segmentation.emptyAudience": undefined;
  "segmentation.emptyAudienceDescription": undefined;
  "segmentation.emptyFilters": undefined;
  "segmentation.emptyFiltersDescription": undefined;
  "segmentation.emptySearch": { searchTerm: string | number };
  "segmentation.emptySearchDescription": undefined;
  "segmentation.estimatedUserCountAudience": { count: number };
  "segmentation.estimatedUserCountSegment": { count: number };
  "segmentation.filterType.apartments": undefined;
  "segmentation.filterType.areas": undefined;
  "segmentation.filterType.buildingLevels": undefined;
  "segmentation.filterType.buildings": undefined;
  "segmentation.filterType.companies": undefined;
  "segmentation.filterType.companyAttributes": undefined;
  "segmentation.filterType.groups": undefined;
  "segmentation.filterType.permissionRoles": undefined;
  "segmentation.filterType.userAttributes": undefined;
  "segmentation.filterType.userType": undefined;
  "segmentation.filterType.flexOperator": undefined;
  "segmentation.filterType.flexTenants": undefined;
  "segmentation.plusMore": { count: string | number };
  "segmentation.saveAudience": undefined;
  "segmentation.segmentDescription": { id: string | number, descrption: string | number };
  "segmentation.segmentName": { id: string | number };
  "segmentation.segmentTooltip": undefined;
  "segmentation.setAudienceTitle": { destinationName: string | number };
  "segmentation.userType.commercial": undefined;
  "segmentation.userType.residential": undefined;
  "segmentation.userType.visitors": undefined;
  "settings.activate": undefined;
  "settings.activateUsers": undefined;
  "settings.activateUsersConfirm": { count: number };
  "settings.activateUsersSuccess": { count: number };
  "settings.addApartment": undefined;
  "settings.addCompany": undefined;
  "settings.addCompanyFromCatalogue": undefined;
  "settings.addFromCatalogue": undefined;
  "settings.addFromCatalogueDescription": undefined;
  "settings.addToSite": undefined;
  "settings.allUsers": undefined;
  "settings.apartmentDeleteMessage": undefined;
  "settings.apartmentDeleteSuccess": undefined;
  "settings.apartments": undefined;
  "settings.apartmentsTooltip": undefined;
  "settings.assignGroups": undefined;
  "settings.assignRoles": undefined;
  "settings.build.addBuilding": undefined;
  "settings.build.addLevel": undefined;
  "settings.build.addLevels": undefined;
  "settings.build.addNewBuilding": undefined;
  "settings.build.addSpace": undefined;
  "settings.build.buildingAddressHint": undefined;
  "settings.build.buildingName": undefined;
  "settings.build.bulkUploadLevels": undefined;
  "settings.build.bulkUploadLevelsPlaceholder": undefined;
  "settings.build.bulkUploadLevelsTooltip": undefined;
  "settings.build.bulkUploadNoItems": undefined;
  "settings.build.bulkUploadSpaces": undefined;
  "settings.build.bulkUploadSpacesPlaceholder": undefined;
  "settings.build.bulkUploadSpacesTooltip": undefined;
  "settings.build.bulkUploadVerify": undefined;
  "settings.build.cancelMessage": undefined;
  "settings.build.cancelMessageBulk": undefined;
  "settings.build.createBuilding": undefined;
  "settings.build.deleteSuccess": { name: string | number };
  "settings.build.editBuilding": undefined;
  "settings.build.enableAddress": undefined;
  "settings.build.enterAreaSize": undefined;
  "settings.build.enterBuildingName": undefined;
  "settings.build.enterNumberOfOccupants": undefined;
  "settings.build.failedToDeleteBuilding": undefined;
  "settings.build.failedToDeleteSpace": undefined;
  "settings.build.failedToUpdateBuildingOrder": undefined;
  "settings.build.failedToUpdateLevelOrder": undefined;
  "settings.build.failedToUpdateSpaceOrder": undefined;
  "settings.build.levelExists": undefined;
  "settings.build.levels": undefined;
  "settings.build.levelsAdded": { count: string | number };
  "settings.build.noBuildings": undefined;
  "settings.build.noLevels": undefined;
  "settings.build.noSpaces": undefined;
  "settings.build.selectUnit": undefined;
  "settings.build.spaces": undefined;
  "settings.build.owner": undefined;
  "settings.build.ownerPlaceholder": undefined;
  "settings.build.widget.header": undefined;
  "settings.build.widget.spacesAdded": { count: string | number };
  "settings.build.widget.spaceAdded": undefined;
  "settings.build.widget.spacesUpdated": undefined;
  "settings.build.widget.newSpace": undefined;
  "settings.build.widget.editSpace": undefined;
  "settings.build.widget.spaceName": undefined;
  "settings.build.widget.spaceNamePlaceholder": undefined;
  "settings.build.widget.typeOfSpace": undefined;
  "settings.build.widget.public": undefined;
  "settings.build.widget.private": undefined;
  "settings.build.widget.publicDesciption": undefined;
  "settings.build.widget.privateDesciption": undefined;
  "settings.build.widget.permissions": undefined;
  "settings.build.widget.permissionsTooltip": undefined;
  "settings.build.widget.addAudience": undefined;
  "settings.build.widget.addSpace": undefined;
  "settings.build.widget.onlySelected": undefined;
  "settings.build.widget.nameRequired": undefined;
  "settings.buildConfiguration": undefined;
  "settings.building": undefined;
  "settings.companies": undefined;
  "settings.companiesTooltip": undefined;
  "settings.companyAdded": { company: string | number };
  "settings.companyDeleteError": undefined;
  "settings.companyDeleteMessage": undefined;
  "settings.companyDeleteSuccess": undefined;
  "settings.companyIndustries.accounting": undefined;
  "settings.companyIndustries.advertising_public_relations": undefined;
  "settings.companyIndustries.aerospace": undefined;
  "settings.companyIndustries.agriculture": undefined;
  "settings.companyIndustries.airlines": undefined;
  "settings.companyIndustries.architectural_services": undefined;
  "settings.companyIndustries.automotive": undefined;
  "settings.companyIndustries.banking": undefined;
  "settings.companyIndustries.bars_and_restaurants": undefined;
  "settings.companyIndustries.building_services": undefined;
  "settings.companyIndustries.communications": undefined;
  "settings.companyIndustries.community_services": undefined;
  "settings.companyIndustries.construction": undefined;
  "settings.companyIndustries.coworking": undefined;
  "settings.companyIndustries.data_management_analytics": undefined;
  "settings.companyIndustries.defense": undefined;
  "settings.companyIndustries.drilling_mining": undefined;
  "settings.companyIndustries.dry_cleaning": undefined;
  "settings.companyIndustries.educational_services": undefined;
  "settings.companyIndustries.electronics": undefined;
  "settings.companyIndustries.energy_and_natural_resources": undefined;
  "settings.companyIndustries.engineering": undefined;
  "settings.companyIndustries.entertainment": undefined;
  "settings.companyIndustries.environment": undefined;
  "settings.companyIndustries.fashion": undefined;
  "settings.companyIndustries.finance": undefined;
  "settings.companyIndustries.food_and_beverage": undefined;
  "settings.companyIndustries.general_contractors": undefined;
  "settings.companyIndustries.government_services": undefined;
  "settings.companyIndustries.graphic_design": undefined;
  "settings.companyIndustries.hardware": undefined;
  "settings.companyIndustries.health_and_wellbeing": undefined;
  "settings.companyIndustries.health_services": undefined;
  "settings.companyIndustries.home_and_furnishings": undefined;
  "settings.companyIndustries.hospitality": undefined;
  "settings.companyIndustries.information_technology": undefined;
  "settings.companyIndustries.insurance": undefined;
  "settings.companyIndustries.lawyers_law_firms": undefined;
  "settings.companyIndustries.legal": undefined;
  "settings.companyIndustries.life_sciences": undefined;
  "settings.companyIndustries.logistics": undefined;
  "settings.companyIndustries.manufacturing": undefined;
  "settings.companyIndustries.maritime": undefined;
  "settings.companyIndustries.media": undefined;
  "settings.companyIndustries.medical": undefined;
  "settings.companyIndustries.not_for_profit": undefined;
  "settings.companyIndustries.other": undefined;
  "settings.companyIndustries.parking": undefined;
  "settings.companyIndustries.pharmaceutical": undefined;
  "settings.companyIndustries.postal_freight": undefined;
  "settings.companyIndustries.printing_and_publishing": undefined;
  "settings.companyIndustries.professional_services": undefined;
  "settings.companyIndustries.real_estate": undefined;
  "settings.companyIndustries.recruitment_and_consulting": undefined;
  "settings.companyIndustries.research": undefined;
  "settings.companyIndustries.retail": undefined;
  "settings.companyIndustries.retailer": undefined;
  "settings.companyIndustries.securities_and_investment": undefined;
  "settings.companyIndustries.security": undefined;
  "settings.companyIndustries.sports_and_fitness": undefined;
  "settings.companyIndustries.technology": undefined;
  "settings.companyIndustries.telecoms": undefined;
  "settings.companyIndustries.tourism": undefined;
  "settings.companyIndustries.transportation": undefined;
  "settings.companyIndustries.urban_development": undefined;
  "settings.companyIndustries.utilities": undefined;
  "settings.companyIndustries.venture_capital": undefined;
  "settings.companyRemoved": { company: string | number };
  "settings.companySettings": undefined;
  "settings.createApartment": undefined;
  "settings.createCompany.addDomain": undefined;
  "settings.createCompany.approvedDomains": undefined;
  "settings.createCompany.attributes": undefined;
  "settings.createCompany.attributesTooltips": undefined;
  "settings.createCompany.cancellingCreation": undefined;
  "settings.createCompany.companyName": undefined;
  "settings.createCompany.companyNameRequired": undefined;
  "settings.createCompany.companyNameTooltips": undefined;
  "settings.createCompany.companySuccessfullyCreated": { companyName: string | number };
  "settings.createCompany.companySuccessfullyUpdated": { companyName: string | number };
  "settings.createCompany.createCompany": undefined;
  "settings.createCompany.createNew": undefined;
  "settings.createCompany.description": undefined;
  "settings.createCompany.emailDomains": undefined;
  "settings.createCompany.industry": undefined;
  "settings.createCompany.industryRequired": undefined;
  "settings.createCompany.industryTooltips": undefined;
  "settings.createCompany.newCompany": undefined;
  "settings.createCompany.saveChanges": undefined;
  "settings.createCompany.searchAttributes": undefined;
  "settings.createCompany.searchHint": undefined;
  "settings.createCompany.searchIndustries": undefined;
  "settings.createCompany.selectIndustry": undefined;
  "settings.createCompany.validationHint": undefined;
  "settings.createCompany.validationTooltips": undefined;
  "settings.createCompany.validationValuesErrorMessage": undefined;
  "settings.dangerZone.confirmDeleteAccount": undefined;
  "settings.dangerZone.deleteAccount": undefined;
  "settings.dangerZone.infoEndUser": undefined;
  "settings.dangerZone.tab": undefined;
  "settings.deactivate": undefined;
  "settings.deactivateUsers": undefined;
  "settings.deactivateUsersConfirm": { count: number };
  "settings.deactivateUsersSuccess": { count: number };
  "settings.editApartment.apartmentName": undefined;
  "settings.editApartment.apartmentNameHint": undefined;
  "settings.editApartment.cancelMessage": undefined;
  "settings.editApartment.catalogueHint": undefined;
  "settings.editApartment.createHint": undefined;
  "settings.editApartment.editApartment": undefined;
  "settings.editApartment.editApartmentInfo": undefined;
  "settings.editApartment.findABuildingOrLevel": undefined;
  "settings.editApartment.nameRequired": undefined;
  "settings.editApartment.newApartment": undefined;
  "settings.editApartment.noBuildingsAtSite": undefined;
  "settings.editApartment.searchHint": undefined;
  "settings.editApartment.selectAtLeastOne": undefined;
  "settings.editApartment.successMessage": { apartment: string | number };
  "settings.editApartment.successUpdateMessage": { apartment: string | number };
  "settings.editBuilding.addressRequired": undefined;
  "settings.editBuilding.areaMax": undefined;
  "settings.editBuilding.nameRequired": undefined;
  "settings.editBuilding.occupantsMax": undefined;
  "settings.editCompany.addCompanyInfo": undefined;
  "settings.editCompany.areYouSureAccessGroupDefault": { name: string | number };
  "settings.editCompany.autoApproveRegistrationsHeading": undefined;
  "settings.editCompany.autoApproveRegistrationsLabel": undefined;
  "settings.editCompany.autoApproveRegistrationsNoExtraInfo": undefined;
  "settings.editCompany.autoApproveRegistrationsNoLabel": undefined;
  "settings.editCompany.autoApproveRegistrationsYesExtraInfo": undefined;
  "settings.editCompany.autoApproveRegistrationsYesLabel": undefined;
  "settings.editCompany.companyUpdateFailed": undefined;
  "settings.editCompany.defaultGroupLabel": undefined;
  "settings.editCompany.editCompany": undefined;
  "settings.editCompany.editCompanyInfo": undefined;
  "settings.editCompany.enterEvolutionTenantCode": undefined;
  "settings.editCompany.evolutionTenantCode": undefined;
  "settings.editCompany.evolutionTenantCodeTooltip": undefined;
  "settings.editCompany.permissionIntro": undefined;
  "settings.editCompany.requestManagement": undefined;
  "settings.editCompany.requestManagementAllCan": undefined;
  "settings.editCompany.requestManagementIntro": undefined;
  "settings.editCompany.requestManagementSelectedUser": undefined;
  "settings.editCompany.requestManagementSelectedUserAdditionalInfo": undefined;
  "settings.editCompany.selectGroupsTitle": undefined;
  "settings.editCompany.selectDefaultAccessGroupsPlaceholder": undefined;
  "settings.editCompany.accessGroupTooltip": undefined;
  "settings.editCompany.addingCardsManuallyTooltip": undefined;
  "settings.editCompany.addingCardsAutomaticallyTooltip": undefined;
  "settings.editCompany.addingCardsLabel": undefined;
  "settings.editCompany.addingCardsAutomaticallyLabel": undefined;
  "settings.editCompany.addingCardsManuallyLabel": undefined;
  "settings.editCompany.setAsDefault": undefined;
  "settings.editCompany.valuesToolTip": undefined;
  "settings.editProfile.apartment": undefined;
  "settings.editProfile.attributes": undefined;
  "settings.editProfile.cancellingAgreement": undefined;
  "settings.editProfile.changesHaveBeenSaved": { firstName: string | number, lastName: string | number };
  "settings.editProfile.changesHaveBeenSavedEndUser": undefined;
  "settings.editProfile.charactersMaximum": undefined;
  "settings.editProfile.charactersMaximumNum": { maxNum: string | number };
  "settings.editProfile.editProfile": undefined;
  "settings.editProfile.emailMustBeValid": undefined;
  "settings.editProfile.emailRequired": undefined;
  "settings.editProfile.firstNameRequired": undefined;
  "settings.editProfile.history": undefined;
  "settings.editProfile.integrations": undefined;
  "settings.editProfile.interests": undefined;
  "settings.editProfile.interestsToolTip": undefined;
  "settings.editProfile.interestsToolTipEndUser": undefined;
  "settings.editProfile.invalidName": undefined;
  "settings.editProfile.lastNameRequired": undefined;
  "settings.editProfile.memberSince": { company: string | number, createdDate: string | number };
  "settings.editProfile.mobileNumberToolTipEndUser": undefined;
  "settings.editProfile.notVerified": undefined;
  "settings.editProfile.password": undefined;
  "settings.editProfile.passwordResetEmailSent": { firstName: string | number, lastName: string | number };
  "settings.editProfile.passwordResetEmailSentEndUser": { email: string | number };
  "settings.editProfile.priority": undefined;
  "settings.editProfile.resendEmail": undefined;
  "settings.editProfile.resendVerificationEmail": undefined;
  "settings.editProfile.resetPassword": undefined;
  "settings.editProfile.resetting": undefined;
  "settings.editProfile.roleToolTip": undefined;
  "settings.editProfile.saveChanges": undefined;
  "settings.editProfile.searchApartments": undefined;
  "settings.editProfile.searchCompanies": undefined;
  "settings.editProfile.selectCompany": undefined;
  "settings.editProfile.selectGroups": undefined;
  "settings.editProfile.selectStatus": undefined;
  "settings.editProfile.selectType": undefined;
  "settings.editProfile.sending": undefined;
  "settings.editProfile.userType": undefined;
  "settings.editProfile.verificationEmailWasResent": { firstName: string | number, lastName: string | number };
  "settings.editProfile.verificationEmailWasResentEndUser": { email: string | number };
  "settings.editProfile.verified": undefined;
  "settings.editStatus": undefined;
  "settings.emailNotVerifiedTooltip": undefined;
  "settings.emailVerifiedTooltip": undefined;
  "settings.emptyApartmentsTable": undefined;
  "settings.emptyApartmentsTableSearch": { search: string | number };
  "settings.emptyCompaniesTable": undefined;
  "settings.emptyCompaniesTableSearch": { search: string | number };
  "settings.emptyUsersTable": undefined;
  "settings.emptyUsersTableSearch": { search: string | number };
  "settings.failedToExportApartments": undefined;
  "settings.failedToExportCompanies": undefined;
  "settings.findACompany": undefined;
  "settings.findAnApartment": undefined;
  "settings.flexMembership.tab": undefined;
  "settings.flexMembership.description": undefined;
  "settings.flexMembership.placeholder": undefined;
  "settings.history.addMessage": undefined;
  "settings.history.changedBy": { name: string | number };
  "settings.history.description": undefined;
  "settings.history.emptyList": undefined;
  "settings.history.messageAdded": { name: string | number };
  "settings.history.title": undefined;
  "settings.history.writeMessage": undefined;
  "settings.integrations.evolution.accessEvolution": undefined;
  "settings.integrations.evolution.accountId": undefined;
  "settings.integrations.evolution.enterAccountId": undefined;
  "settings.integrations.evolution.multiAccountError": undefined;
  "settings.integrations.title": undefined;
  "settings.levels": undefined;
  "settings.levelsHint": undefined;
  "settings.levelsHintApartment": undefined;
  "settings.levelsRequired": undefined;
  "settings.moduleNotEnabled": undefined;
  "settings.noResultsForSearch": { search: string | number };
  "settings.notifications.bookingPreferences.admin": undefined;
  "settings.notifications.bookingPreferences.adminApprovalRequestEmail": undefined;
  "settings.notifications.bookingPreferences.adminConfirmationEmail": undefined;
  "settings.notifications.bookingPreferences.adminUpdateEmail": undefined;
  "settings.notifications.bookingPreferences.adminCancellationEmail": undefined;
  "settings.notifications.bookingPreferences.adminDeclinedBookingEmail": undefined;
  "settings.notifications.bookingPreferences.endUser": undefined;
  "settings.notifications.bookingPreferences.endUserAwaitingApprovalEmail": undefined;
  "settings.notifications.bookingPreferences.endUserCancellationEmail": undefined;
  "settings.notifications.bookingPreferences.endUserChargedAdjustmentEmail": undefined;
  "settings.notifications.bookingPreferences.endUserConfirmationEmail": undefined;
  "settings.notifications.bookingPreferences.endUserDeclinedBookingEmail": undefined;
  "settings.notifications.bookingPreferences.endUserReminderEmail": undefined;
  "settings.notifications.bookingPreferences.endUserUpdateEmail": undefined;
  "settings.notifications.bookingPreferences.selectAll": undefined;
  "settings.notifications.toolTips.general.warning": undefined;
  "settings.notifications.toolTips.general.emails": undefined;
  "settings.notifications.toolTips.general.pushNotifications": undefined;
  "settings.notifications.toolTips.bookings.admin.header": undefined;
  "settings.notifications.toolTips.bookings.admin.approvalRequest": undefined;
  "settings.notifications.toolTips.bookings.admin.confirmation": undefined;
  "settings.notifications.toolTips.bookings.admin.update": undefined;
  "settings.notifications.toolTips.bookings.admin.cancellation": undefined;
  "settings.notifications.toolTips.bookings.admin.decline": undefined;
  "settings.notifications.toolTips.bookings.endUser.header": undefined;
  "settings.notifications.toolTips.bookings.endUser.awaitingApproval": undefined;
  "settings.notifications.toolTips.bookings.endUser.cancellation": undefined;
  "settings.notifications.toolTips.bookings.endUser.chargeAdjustment": undefined;
  "settings.notifications.toolTips.bookings.endUser.confirmation": undefined;
  "settings.notifications.toolTips.bookings.endUser.decline": undefined;
  "settings.notifications.toolTips.bookings.endUser.reminder": undefined;
  "settings.notifications.toolTips.bookings.endUser.update": undefined;
  "settings.notifications.toolTips.userAccounts.awaitingApproval": undefined;
  "settings.notifications.toolTips.userAccounts.awaitingApprovalInfo": undefined;
  "settings.notifications.bookings": undefined;
  "settings.notifications.general": undefined;
  "settings.notifications.userAccounts": undefined;
  "settings.notifications.pushNotifications": undefined;
  "settings.notifications.userApprovals": undefined;
  "settings.notifications.tab": undefined;
  "settings.notifications.title": undefined;
  "settings.notifications.allSites": undefined;
  "settings.payment.addCreditCardButton": undefined;
  "settings.payment.deleteCreditCardSuccessNotification": undefined;
  "settings.payment.deleteCreditCardTitle": undefined;
  "settings.payment.descriptionRequired": undefined;
  "settings.payment.savedExistingCardSuccessNotification": undefined;
  "settings.payment.savedNewCardSuccessNotification": undefined;
  "settings.payment.setAsDefaultCreditCard": undefined;
  "settings.payment.tab": undefined;
  "settings.permissions.permissionsHaveMoved": undefined;
  "settings.permissions.permissionsHaveMovedDetails": undefined;
  "settings.profileStatus.activateSuccess": { name: string | number };
  "settings.profileStatus.activateUser": undefined;
  "settings.profileStatus.active": undefined;
  "settings.profileStatus.deactivateConfirmation": { name: string | number };
  "settings.profileStatus.deactivateSuccess": { name: string | number };
  "settings.profileStatus.deactivateUser": undefined;
  "settings.profileStatus.deactivated": undefined;
  "settings.profileStatus.pendingApproval": undefined;
  "settings.roles.info": undefined;
  "settings.roles.sitesWhereUserHasRoles": undefined;
  "settings.roles.sitesWhereUserHasNoRoles": undefined;
  "settings.roles.global": undefined;
  "settings.roles.resourceTypes.building": { count: number };
  "settings.roles.resourceTypes.buildingCompany": { count: number };
  "settings.roles.resourceTypes.client": { count: number };
  "settings.roles.resourceTypes.company": { count: number };
  "settings.roles.resourceTypes.region": { count: number };
  "settings.roles.resourceTypes.site": { count: number };
  "settings.roles.resourceTypes.siteCompany": { count: number };
  "settings.roles.resourceTypes.unknown": { count: number };
  "settings.roles.roleInfo.Building Manager": undefined;
  "settings.roles.roleInfo.Property Manager": undefined;
  "settings.roles.roleInfo.Receptionist": undefined;
  "settings.roles.roleInfo.Regional Manager": undefined;
  "settings.roles.roleInfo.Request Assignee": undefined;
  "settings.roles.roleInfo.Request Manager": undefined;
  "settings.roles.roleInfo.Request Reporter": undefined;
  "settings.roles.roleInfo.Super Administrator": undefined;
  "settings.roles.roleInfo.Flex Manager": undefined;
  "settings.roles.roleInfo.Unknown": undefined;
  "settings.roles.roleInfo.Workplace Manager": undefined;
  "settings.roles.roleInfo.Booking Manager": undefined;
  "settings.roles.roleInfo.Booking Observer": undefined;
  "settings.roles.roleNames.Building Manager": { count: number };
  "settings.roles.roleNames.Property Manager": { count: number };
  "settings.roles.roleNames.Receptionist": { count: number };
  "settings.roles.roleNames.Regional Manager": { count: number };
  "settings.roles.roleNames.Request Assignee": { count: number };
  "settings.roles.roleNames.Request Manager": { count: number };
  "settings.roles.roleNames.Request Reporter": { count: number };
  "settings.roles.roleNames.Super Administrator": { count: number };
  "settings.roles.roleNames.Unknown": { count: number };
  "settings.roles.roleNames.Workplace Manager": { count: number };
  "settings.roles.roleNames.Booking Manager": { count: number };
  "settings.roles.roleNames.Booking Observer": { count: number };
  "settings.roles.roleNames.Flex Manager": { count: number };
  "settings.roles.selectResourceType": { type: string | number };
  "settings.roles.tab": undefined;
  "settings.roles.noGrantableRoles": undefined;
  "settings.selectApartment": undefined;
  "settings.selectCompany": undefined;
  "settings.spaces": undefined;
  "settings.updateRolesFailed": undefined;
  "settings.updateRolesSuccess": { count: number };
  "settings.userFilters.authorizationRoles": undefined;
  "settings.userFilters.emailSubscription": undefined;
  "settings.userFilters.interests": undefined;
  "settings.userFilters.lastSeen": undefined;
  "settings.userFilters.memberSince": undefined;
  "settings.userFilters.name": undefined;
  "settings.userFilters.userType": undefined;
  "settings.userFilters.verifiedEmail": undefined;
  "settings.userType.commercial": undefined;
  "settings.userType.residential": undefined;
  "settings.userType.visitor": undefined;
  "settings.users": undefined;
  "uploader.clickToUpload": undefined;
  "uploader.dragAndDrop": undefined;
  "uploader.dragAndDropFile": undefined;
  "uploader.processing": undefined;
  "uploader.uploadImage": undefined;
  "uploader.uploading": undefined;
  "credits.emptyList": undefined;
  "credits.title": undefined;
  "credits.pageTitle": undefined;
  "credits.csvExportSuccess": undefined;
  "credits.additionalCredits": undefined;
  "credits.activityPageTitle": undefined;
  "credits.additionalCreditsPurchase.title": undefined;
  "credits.additionalCreditsPurchase.tooltip": undefined;
  "credits.adjustments.modalTitle": undefined;
  "credits.adjustments.title": undefined;
  "credits.adjustments.amountOfCredits": undefined;
  "credits.adjustments.selectMembership": undefined;
  "credits.adjustments.selectCompany": undefined;
  "credits.adjustments.flexTenantBalance": { amount: string | number };
  "credits.adjustments.amountOfCreditsPlaceholder": undefined;
  "credits.adjustments.goToActivity": undefined;
  "credits.adjustments.successfullyAdded": { company: string | number };
  "credits.adjustments.alertCompanies": undefined;
  "credits.netPriceBeforeDiscount": undefined;
  "credits.dashboard": undefined;
  "credits.allTenants": undefined;
  "credits.type": undefined;
  "credits.date": undefined;
  "credits.user": undefined;
  "credits.company": undefined;
  "credits.membership": undefined;
  "credits.creditMonth": undefined;
  "credits.amount": undefined;
  "credits.balance": undefined;
  "credits.netCost": undefined;
  "credits.tax": undefined;
  "credits.vat": undefined;
  "credits.discountAmount": { percentage: string | number };
  "credits.creditsAmount": undefined;
  "credits.netPrice": undefined;
  "credits.selectDiscount": undefined;
  "credits.successfullyUpdated": undefined;
  "credits.total": undefined;
  "credits.reset": undefined;
  "credits.creditPackages": undefined;
  "credits.addCreditPackage": undefined;
  "credits.csvExport": undefined;
  "credits.viewBookingDetails": undefined;
  "credits.viewReceipt": undefined;
  "credits.viewInvoice": undefined;
  "credits.manageCreditsSettings": undefined;
  "credits.creditsAlert": undefined;
  "credits.creditsDisabled.notificationTitle": undefined;
  "credits.creditsEnabled.notificationTitle": undefined;
  "credits.creditsEnabled.checkboxLabel": undefined;
  "credits.removeCredit.title": undefined;
  "credits.removeCredit.description": undefined;
  "credits.disablePurchase.description": undefined;
  "credits.discardChanges.description": undefined;
  "credits.settings.general": undefined;
  "credits.types.charge": undefined;
  "credits.types.adjustment": undefined;
  "credits.types.purchase": undefined;
  "credits.types.refund": undefined;
  "credits.emptyState.title": undefined;
  "credits.emptyState.description": undefined;
  "credits.thisMonth": undefined;
  "credits.overview.tooltip": undefined;
  "credits.overview.tooltipFlexManager": undefined;
  "credits.overview.title": undefined;
  "credits.overview.creditsUsage": undefined;
  "credits.overview.balance": undefined;
  "credits.overview.creditsUsed": undefined;
  "credits.overview.purchasedCredits": undefined;
  "credits.overview.purchasedCreditsTooltip": undefined;
  "credits.overview.balanceAdjustments": undefined;
  "credits.overview.balanceAdjustmentsTooltip": undefined;
  "credits.overview.addCredits": undefined;
  "credits.overview.adjustCredits": undefined;
  "credits.overview.noCreditsAvailable": undefined;
  "credits.overview.availableCredits.title": undefined;
  "credits.overview.availableCredits.tooltip": undefined;
  "credits.overview.availableCredits.tooltipFlexManager": undefined;
  "credits.overview.total": undefined;
  "credits.overview.totalCredits": { credits: string | number };
  "credits.overview.creditPreviousMonth": { credits: string | number };
  "credits.overview.monthly": undefined;
  "credits.overview.additional": undefined;
  "credits.overview.additionalCredits.title": undefined;
  "credits.overview.additionalCredits.tooltip": undefined;
  "credits.recent.title": undefined;
  "credits.recent.creditsActivity": undefined;
  "credits.recent.viewActivity": undefined;
  "credits.recent.tooltip": undefined;
  "credits.recent.tooltipFlexManager": undefined;
  "credits.purchaseWidget.header": undefined;
  "credits.purchaseWidget.membershipName": undefined;
  "credits.purchaseWidget.requestAdditionalCredits": undefined;
  "credits.purchaseWidget.goToCheckout": undefined;
  "credits.purchaseWidget.amountOfCredits": undefined;
  "credits.purchaseWidget.requestCredits": { amount: string | number };
  "credits.purchaseWidget.selectMembership": undefined;
  "credits.purchaseWidget.credits": { amount: string | number };
  "credits.purchaseWidget.info": undefined;
  "credits.purchaseWidget.checkout": undefined;
  "credits.purchaseWidget.error.noMembership": undefined;
  "credits.purchaseWidget.toast.success": undefined;
  "credits.purchaseWidget.toast.goToActivityReport": undefined;
  "visitors.appointmentForm.addAnotherDate": undefined;
  "visitors.appointmentForm.addRecipient": undefined;
  "visitors.appointmentForm.addVisitor": undefined;
  "visitors.appointmentForm.addVisitorInformation": undefined;
  "visitors.appointmentForm.addVisitors": undefined;
  "visitors.appointmentForm.addVisitorsIntro": undefined;
  "visitors.appointmentForm.addedVisitors": undefined;
  "visitors.appointmentForm.appointmentCreateRecurringItemTitle": undefined;
  "visitors.appointmentForm.appointmentCreated": undefined;
  "visitors.appointmentForm.appointmentCreatedBody": undefined;
  "visitors.appointmentForm.appointmentCreatedIntro": undefined;
  "visitors.appointmentForm.appointmentDetails": undefined;
  "visitors.appointmentForm.appointmentDuration": { minutes: string | number, time: string | number };
  "visitors.appointmentForm.appointmentOccurence": undefined;
  "visitors.appointmentForm.appointmentPreviewRecurringText": { repeatType: string | number, recurringEndDate: string | number, times: string | number };
  "visitors.appointmentForm.appointmentRecurrence": undefined;
  "visitors.appointmentForm.appointmentRecurring": undefined;
  "visitors.appointmentForm.appointmentRecurringDeleteRecurringAppointmentHeader": undefined;
  "visitors.appointmentForm.appointmentRecurringDeleteThisAndFollowing": undefined;
  "visitors.appointmentForm.appointmentRecurringDeleteThisOne": undefined;
  "visitors.appointmentForm.appointmentRecurringEditRecurringAppointmentHeader": undefined;
  "visitors.appointmentForm.appointmentRecurringLast": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEndsAfter": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEndsOccurrences": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEndsOn": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEndsOnDay": { dayNumber: string | number };
  "visitors.appointmentForm.appointmentRecurringOccursEndsOnLastOccurance": { weekday: string | number };
  "visitors.appointmentForm.appointmentRecurringOccursEndsOnSameOccurance": { number: string | number, weekday: string | number };
  "visitors.appointmentForm.appointmentRecurringOccursEveryDay": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEveryDays": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEveryMonth": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEveryMonths": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEveryWeek": undefined;
  "visitors.appointmentForm.appointmentRecurringOccursEveryWeeks": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsEnds": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsFridayFriday": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsFridayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsFridayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsMondayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsMondayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsMondayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsRepeatEvery": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsRepeatOn": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSaturdayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSaturdayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSaturdayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSelector": { repeatType: string | number, recurringEndDate: string | number };
  "visitors.appointmentForm.appointmentRecurringSettingsSelectorWeekly": { repeatType: string | number, days: string | number, recurringEndDate: string | number };
  "visitors.appointmentForm.appointmentRecurringSettingsSundayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSundayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsSundayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsText": { repeatType: string | number, startTime: string | number, endTime: string | number, recurringEndDate: string | number, times: string | number };
  "visitors.appointmentForm.appointmentRecurringSettingsThursdayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsThursdayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsThursdayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsTuesdayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsTuesdayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsTuesdayShortened": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsWednesdayLong": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsWednesdayShort": undefined;
  "visitors.appointmentForm.appointmentRecurringSettingsWednesdayShortened": undefined;
  "visitors.appointmentForm.appointmentRepeat": undefined;
  "visitors.appointmentForm.appointmentRepeatTypeDaily": undefined;
  "visitors.appointmentForm.appointmentRepeatTypeMonthly": undefined;
  "visitors.appointmentForm.appointmentRepeatTypeNone": undefined;
  "visitors.appointmentForm.appointmentRepeatTypeWeekly": undefined;
  "visitors.appointmentForm.appointmentUpdated": undefined;
  "visitors.appointmentForm.appointmentUpdatedBody": undefined;
  "visitors.appointmentForm.appointmentUpdatedIntro": undefined;
  "visitors.appointmentForm.cancelBody": undefined;
  "visitors.appointmentForm.cancelBodyCloseTab": undefined;
  "visitors.appointmentForm.cancelNo": undefined;
  "visitors.appointmentForm.cancelYes": undefined;
  "visitors.appointmentForm.changeHost": undefined;
  "visitors.appointmentForm.changesHaveBeenSaved": undefined;
  "visitors.appointmentForm.companyReceptionNotesTooltip": undefined;
  "visitors.appointmentForm.companyTooltip": undefined;
  "visitors.appointmentForm.companyValidation": undefined;
  "visitors.appointmentForm.createNewHost": undefined;
  "visitors.appointmentForm.createNewVisitor": undefined;
  "visitors.appointmentForm.dateValidation": undefined;
  "visitors.appointmentForm.descriptionPlaceholder": undefined;
  "visitors.appointmentForm.descriptionTooltip": undefined;
  "visitors.appointmentForm.descrption": undefined;
  "visitors.appointmentForm.duration": undefined;
  "visitors.appointmentForm.durationSelect": undefined;
  "visitors.appointmentForm.editBreadcrumb": { title: string | number };
  "visitors.appointmentForm.editVisitorInformation": undefined;
  "visitors.appointmentForm.emailTooltip": undefined;
  "visitors.appointmentForm.firstNameValidation": undefined;
  "visitors.appointmentForm.hostAdded": { name: string | number };
  "visitors.appointmentForm.hostRemoved": { name: string | number };
  "visitors.appointmentForm.hostTooltip": undefined;
  "visitors.appointmentForm.lastNameValidation": undefined;
  "visitors.appointmentForm.locationSelect": undefined;
  "visitors.appointmentForm.newHost": undefined;
  "visitors.appointmentForm.noBuildingsError": undefined;
  "visitors.appointmentForm.noteToReception": undefined;
  "visitors.appointmentForm.notifyHost": undefined;
  "visitors.appointmentForm.dontNotifyHost": undefined;
  "visitors.appointmentForm.notifyHostTooltip": { userType: string | number };
  "visitors.appointmentForm.receptionNotes": { prefix: string | number };
  "visitors.appointmentForm.receptionNotesPlaceholder": undefined;
  "visitors.appointmentForm.receptionNotesTooltip": undefined;
  "visitors.appointmentForm.selectCompany": undefined;
  "visitors.appointmentForm.cannotEditBookingInformation": undefined;
  "visitors.appointmentForm.setVisitorAs": undefined;
  "visitors.appointmentForm.sharedNotifications": undefined;
  "visitors.appointmentForm.startTime": undefined;
  "visitors.appointmentForm.suggestedHosts": undefined;
  "visitors.appointmentForm.suggestedHostsEmpty": undefined;
  "visitors.appointmentForm.suggestedVisitors": undefined;
  "visitors.appointmentForm.siteBookingNotAchievable": undefined;
  "visitors.appointmentForm.timeValidation": undefined;
  "visitors.appointmentForm.title": undefined;
  "visitors.appointmentForm.titlePlaceholder": undefined;
  "visitors.appointmentForm.titleValidation": { value: string | number };
  "visitors.appointmentForm.visitorInformation": undefined;
  "visitors.appointmentForm.visitorNoteToReception": undefined;
  "visitors.appointmentForm.visitorNoteToReceptionPlaceholder": undefined;
  "visitors.appointmentForm.visitorNoteToReceptionValidation": undefined;
  "visitors.appointmentForm.visitorNotes": undefined;
  "visitors.appointmentForm.visitorNotesPlaceholder": undefined;
  "visitors.appointmentForm.visitorNotesTooltip": undefined;
  "visitors.appointmentForm.visitorRemoved": { name: string | number };
  "visitors.appointmentForm.visitorTypeValidation": undefined;
  "visitors.appointmentForm.visitorsAdded": { count: string | number };
  "visitors.appointmentForm.visitorsValidation": undefined;
  "visitors.appointments.allAppointments": undefined;
  "visitors.appointments.appointmentDelete": undefined;
  "visitors.appointments.appointmentDeleteConfirm": undefined;
  "visitors.appointments.appointmentDeleted": undefined;
  "visitors.appointments.appointmentsTab": undefined;
  "visitors.appointments.createVisitorAppointment": undefined;
  "visitors.appointments.bookingReference": undefined;
  "visitors.appointments.modifyBooking": undefined;
  "visitors.appointments.manageBooking": undefined;
  "visitors.appointments.csvExport": undefined;
  "visitors.appointments.editAppointment": undefined;
  "visitors.appointments.editAppointmentWithName": { name: string | number };
  "visitors.appointments.emptyList": undefined;
  "visitors.appointments.findGuest": undefined;
  "visitors.appointments.getInTouch": undefined;
  "visitors.appointments.getStarted": undefined;
  "visitors.appointments.guestsList": { count: string | number };
  "visitors.appointments.myAppointments": undefined;
  "visitors.appointments.newAppointment": undefined;
  "visitors.appointments.notFound": undefined;
  "visitors.appointments.notes": undefined;
  "visitors.appointments.receptionNotes": { name: string | number };
  "visitors.appointments.receptionView": undefined;
  "visitors.appointments.viewBookVisitors": undefined;
  "visitors.appointments.visitorAppointments": undefined;
  "visitors.appointments.visitorNotes": undefined;
  "visitors.appointments.visitorsBulkUploadConfirmation": { count: string | number };
  "visitors.appointments.visitors": { count: number };
  "visitors.visitorTypes.walkIn": undefined;
  "visitors.visitorTypes.preBooked": undefined;
  "visitors.visitorTypes.visitorTypeExists": { name: string | number };
  "visitors.common.csvExportSuccess": undefined;
  "visitors.common.building": undefined;
  "visitors.common.end": undefined;
  "visitors.common.level": undefined;
  "visitors.common.location": undefined;
  "visitors.common.organizer": undefined;
  "visitors.common.reception": undefined;
  "visitors.common.receptionSuffix": undefined;
  "visitors.common.receptionists": undefined;
  "visitors.common.recurring": undefined;
  "visitors.common.showMore": undefined;
  "visitors.common.start": undefined;
  "visitors.common.tenant": undefined;
  "visitors.common.viewAll": undefined;
  "visitors.common.visitor": undefined;
  "visitors.common.visitorManagement": undefined;
  "visitors.common.visitors": undefined;
  "visitors.common.visitorsType": undefined;
  "visitors.common.visitorType": undefined;
  "visitors.csv.appointmentDate": undefined;
  "visitors.csv.appointmentDuration": undefined;
  "visitors.csv.appointmentEndTime": undefined;
  "visitors.csv.appointmentStartTime": undefined;
  "visitors.csv.appointmentStatus": undefined;
  "visitors.csv.appointmentTime": undefined;
  "visitors.csv.buildingReceptionNotes": undefined;
  "visitors.csv.cardIdNumber": undefined;
  "visitors.csv.cardIdStatus": undefined;
  "visitors.csv.checkInDateTime": undefined;
  "visitors.csv.checkOutDateTime": undefined;
  "visitors.csv.checkedInBy": undefined;
  "visitors.csv.checkedOutBy": undefined;
  "visitors.csv.copyAndPasteColumnsHere": undefined;
  "visitors.csv.copyAndPasteInfoLine1": undefined;
  "visitors.csv.copyAndPasteInfoTooltip": undefined;
  "visitors.csv.createdBy": undefined;
  "visitors.csv.dateTimeCreated": undefined;
  "visitors.csv.downloadTemplateInfoLine1": undefined;
  "visitors.csv.downloadTemplateInfoLine2": undefined;
  "visitors.csv.floorLevel": undefined;
  "visitors.csv.hostCompany": undefined;
  "visitors.csv.hostCompanyStrict": undefined;
  "visitors.csv.hostNotification": undefined;
  "visitors.csv.location": undefined;
  "visitors.csv.maximumLimitReached": { amount: string | number };
  "visitors.csv.meetingTitle": undefined;
  "visitors.csv.notes": undefined;
  "visitors.csv.preBooked": undefined;
  "visitors.csv.preBookedWalkIn": undefined;
  "visitors.csv.tenantReceptionNotes": undefined;
  "visitors.csv.uploadCsvFile": undefined;
  "visitors.csv.visitor": undefined;
  "visitors.csv.visitorCompany": undefined;
  "visitors.csv.walkIn": undefined;
  "visitors.reception.notificationSent": undefined;
  "visitors.reception.accessCardReturn": undefined;
  "visitors.reception.accessPassSent": undefined;
  "visitors.reception.action": undefined;
  "visitors.reception.activityHistory": undefined;
  "visitors.reception.appointment": undefined;
  "visitors.reception.appointmentCreated": undefined;
  "visitors.reception.appointmentUpdated": undefined;
  "visitors.reception.appointmentsView": undefined;
  "visitors.reception.barrierControlAccess": undefined;
  "visitors.reception.barrierControlAccessTooltip": undefined;
  "visitors.reception.cardId": undefined;
  "visitors.reception.cardNotReturned": undefined;
  "visitors.reception.cardReturnBody": undefined;
  "visitors.reception.cardReturned": undefined;
  "visitors.reception.checkIn": undefined;
  "visitors.reception.checkOut": undefined;
  "visitors.reception.createNew": undefined;
  "visitors.reception.createNote": undefined;
  "visitors.reception.createNoteForAppointment": undefined;
  "visitors.reception.csvExport": undefined;
  "visitors.reception.csvExporting": undefined;
  "visitors.reception.deleteConfirm": { receptionName: string | number };
  "visitors.reception.deleteInfoLine1": undefined;
  "visitors.reception.deleteInfoLine10": undefined;
  "visitors.reception.deleteInfoLine2": undefined;
  "visitors.reception.deleteInfoLine3": undefined;
  "visitors.reception.deleteInfoLine4": undefined;
  "visitors.reception.deleteInfoLine5": undefined;
  "visitors.reception.deleteInfoLine6": undefined;
  "visitors.reception.deleteInfoLine7": undefined;
  "visitors.reception.deleteInfoLine8": undefined;
  "visitors.reception.deleteInfoLine9": undefined;
  "visitors.reception.deleteInfoTitle": { title: string | number, count: string | number };
  "visitors.reception.deleteReceptionistNoteConfirm": undefined;
  "visitors.reception.deleteSchedule": undefined;
  "visitors.reception.deleteScheduleAfter": undefined;
  "visitors.reception.deleteScheduleError": { receptionName: string | number };
  "visitors.reception.deleteScheduleSuccess": { receptionName: string | number, date: string | number };
  "visitors.reception.deleteScheduleTitle": undefined;
  "visitors.reception.deleteScheduleTooltip": undefined;
  "visitors.reception.deleteSuccess": { receptionName: string | number };
  "visitors.reception.deleteTitle": undefined;
  "visitors.reception.details": undefined;
  "visitors.reception.displayName": { prefix: string | number, suffix: string | number };
  "visitors.reception.editAppointment": undefined;
  "visitors.reception.emailService": undefined;
  "visitors.reception.emptyList": undefined;
  "visitors.reception.enterCardId": undefined;
  "visitors.reception.failedToLoad": undefined;
  "visitors.reception.goToStatus": { status: string | number };
  "visitors.reception.markCardAsNotReturned": undefined;
  "visitors.reception.markCardAsReturned": undefined;
  "visitors.reception.meetingTitle": undefined;
  "visitors.reception.multipleWalkInSuccess": { name: string | number, count: string | number };
  "visitors.reception.newAppointment": undefined;
  "visitors.reception.notes": undefined;
  "visitors.reception.notesIntro": { organizerName: string | number };
  "visitors.reception.onSiteBarrier": undefined;
  "visitors.reception.printPass": undefined;
  "visitors.reception.printPasses": undefined;
  "visitors.reception.receptionNotes": undefined;
  "visitors.reception.notify": undefined;
  "visitors.reception.receptionistNote": { name: string | number, date: string | number };
  "visitors.reception.receptionistNoteTooltip": undefined;
  "visitors.reception.recurringAppointment": undefined;
  "visitors.reception.resendAppointmentCodeEmail": undefined;
  "visitors.reception.resendAppointmentCodeEmailSuccess": { email: string | number };
  "visitors.reception.searchPlaceholder": undefined;
  "visitors.reception.selectIntro": undefined;
  "visitors.reception.singleWalkInSuccess": { name: string | number };
  "visitors.reception.statusChanged": { name: string | number, status: string | number };
  "visitors.reception.statusCheckedIn": undefined;
  "visitors.reception.statusCheckedOut": undefined;
  "visitors.reception.statusPrebooked": undefined;
  "visitors.reception.viewAppointment": undefined;
  "visitors.reception.walkIn": undefined;
  "visitors.receptions.accessReception": undefined;
  "visitors.receptions.allVisitorsForSiteName": { siteName: string | number };
  "visitors.receptions.createReception": undefined;
  "visitors.receptions.emptyList": undefined;
  "visitors.receptions.emptyListCreate": undefined;
  "visitors.receptions.preBookedVisitorsToday": { count: string | number };
  "visitors.receptions.preBookedVisitorsTomorrow": { count: string | number };
  "visitors.receptions.selectReception": undefined;
  "visitors.receptions.csv.siteName": undefined;
  "visitors.receptions.csv.createdBy": undefined;
  "visitors.receptions.csv.receptionName": undefined;
  "visitors.receptions.csv.passPrintingEnabled": undefined;
  "visitors.receptions.csv.sendPassOnCheckIn": undefined;
  "visitors.receptions.csv.barrierControlAccessGroup": undefined;
  "visitors.receptions.csv.receptionistNameAndEmail": { number: string | number };
  "visitors.settings.activationTime": undefined;
  "visitors.settings.addType": undefined;
  "visitors.settings.addVisitorTypePlaceholder": undefined;
  "visitors.settings.allUsersCanInvite": undefined;
  "visitors.settings.buildingImageUploadError": undefined;
  "visitors.settings.buildingReception": undefined;
  "visitors.settings.buildingSettings": undefined;
  "visitors.settings.buildingSettingsv2": undefined;
  "visitors.settings.buildingTooltip": undefined;
  "visitors.settings.chooseAppointmentType": undefined;
  "visitors.settings.cancelChangesBody": undefined;
  "visitors.settings.cancelDeletion": undefined;
  "visitors.settings.cardIdTracking": undefined;
  "visitors.settings.cardIdTrackingTooltip": undefined;
  "visitors.settings.changesSaved": undefined;
  "visitors.settings.deactivationTime": undefined;
  "visitors.settings.mandatoryHostEmail": undefined;
  "visitors.settings.mandatoryHostEmailCheckbox": undefined;
  "visitors.settings.enableVisitorTypes": undefined;
  "visitors.settings.emailBrandingTooltip": undefined;
  "visitors.settings.emailFooter": undefined;
  "visitors.settings.emailFooterText": undefined;
  "visitors.settings.emailFooterTextPlaceholder": undefined;
  "visitors.settings.emailFooterTextTooltip": undefined;
  "visitors.settings.emailHeader": undefined;
  "visitors.settings.employeeSettings": undefined;
  "visitors.settings.employeeSettingsv2": undefined;
  "visitors.settings.emptyUsersList": undefined;
  "visitors.settings.emptyUsersListInfo": undefined;
  "visitors.settings.enableCardId": undefined;
  "visitors.settings.enablePassPrinting": undefined;
  "visitors.settings.generalSettings": undefined;
  "visitors.settings.inviteUser": undefined;
  "visitors.settings.levelTooltip": undefined;
  "visitors.settings.manageBuildingSettings": undefined;
  "visitors.settings.manageGeneralSettings": undefined;
  "visitors.settings.managePermissions": undefined;
  "visitors.settings.manageReceptions": undefined;
  "visitors.settings.maximumCreationDuration": undefined;
  "visitors.settings.maximumCreationDurationTooltip": undefined;
  "visitors.settings.maximumCreationDurationTooltipCompany": undefined;
  "visitors.settings.maximumCreationDurationTooltipCompanyWorkplaceManager": { num: string | number };
  "visitors.settings.maximumCreationDurationUpMonths": undefined;
  "visitors.settings.maximumCreationDurationUpToNumberOfMonths": undefined;
  "visitors.settings.minutesAfterMeetingEndTime": { value: string | number };
  "visitors.settings.minutesBeforeMeetingStartTime": { value: string | number };
  "visitors.settings.noUsers": { value: string | number };
  "visitors.settings.onlyUsersCanInvite": undefined;
  "visitors.settings.passAccessTimes": undefined;
  "visitors.settings.passAccessTimesTooltip": undefined;
  "visitors.settings.passConfiguration": undefined;
  "visitors.settings.passDelivery": undefined;
  "visitors.settings.passDeliveryTooltip": undefined;
  "visitors.settings.holdInLobby": undefined;
  "visitors.settings.holdInLobbyHostEmailAlert": undefined;
  "visitors.settings.holdInLobbyHostEmailRequired": undefined;
  "visitors.settings.holdInLobbyLabel": undefined;
  "visitors.settings.holdInLobbyTooltip": undefined;
  "visitors.settings.enableHoldInLobby": undefined;
  "visitors.settings.enableHoldInLobbyLabel": undefined;
  "visitors.settings.enableHoldInLobbyTooltip": undefined;
  "visitors.settings.passMarginTop": undefined;
  "visitors.settings.passMarginTopTooltip": undefined;
  "visitors.settings.passPrinting": undefined;
  "visitors.settings.passPrintingOnCheckIn": undefined;
  "visitors.settings.passPrintingTooltip": undefined;
  "visitors.settings.pendingDeletion": { date: string | number };
  "visitors.settings.permissionSettingsCancelBody": undefined;
  "visitors.settings.permissionsSaved": undefined;
  "visitors.settings.printTestPass": undefined;
  "visitors.settings.receptionBuildingTooltip": undefined;
  "visitors.settings.receptionCancelDeletion": { receptionName: string | number };
  "visitors.settings.receptionCreated": { name: string | number };
  "visitors.settings.receptionEditCancelBody": undefined;
  "visitors.settings.receptionLoadFailed": undefined;
  "visitors.settings.receptionNotifications": undefined;
  "visitors.settings.receptionNotificationsTooltip": undefined;
  "visitors.settings.receptionSettings": undefined;
  "visitors.settings.receptionSettingsv2": undefined;
  "visitors.settings.receptionSuffixPlaceholder": undefined;
  "visitors.settings.receptionSuffixTooltip": undefined;
  "visitors.settings.receptionType": undefined;
  "visitors.settings.receptionTypeTooltip": undefined;
  "visitors.settings.receptionUpdated": undefined;
  "visitors.settings.receptionistsEmptyList": undefined;
  "visitors.settings.receptionistsGetStarted": undefined;
  "visitors.settings.receptionistsTooltip": undefined;
  "visitors.settings.selectBuilding": undefined;
  "visitors.settings.selectCompany": undefined;
  "visitors.settings.selectLevel": undefined;
  "visitors.settings.selectReceptionType": undefined;
  "visitors.settings.sendPassOnCheckIn": undefined;
  "visitors.settings.setTime": undefined;
  "visitors.settings.setAsDefault": undefined;
  "visitors.settings.tenantReception": undefined;
  "visitors.settings.tenantReceptionTooltip": undefined;
  "visitors.settings.tenantSelectPlaceholder": undefined;
  "visitors.settings.tenantSelectTooltip": undefined;
  "visitors.settings.usersWithInvitePermissions": undefined;
  "visitors.settings.usersWithInvitePermissionsTooltip": undefined;
  "visitors.settings.validationActivationTime": undefined;
  "visitors.settings.validationDeactivationTime": undefined;
  "visitors.settings.validationDeactivationTimeAfter": undefined;
  "visitors.settings.validationEmailFooterMax": { max: string | number };
  "visitors.settings.validationMarginTopMax": { max: string | number };
  "visitors.settings.visitorsBlockTitle": undefined;
  "visitors.settings.visitorsBlockTooltip": undefined;
  "visitors.settings.visitorTypes": undefined;
  "visitors.settings.areYouSureDefault": { name: string | number };
  "visitors.settings.setDefaultDescriptionModal": undefined;
  "visitors.settings.visitorTypesTooltip": undefined;
  "visitors.settings.walletPass.colorValidation": undefined;
  "visitors.settings.walletPass.accessPasses": undefined;
  "visitors.settings.walletPass.mobileWalletPassConfiguration": undefined;
  "visitors.settings.walletPass.backgroundColor": undefined;
  "visitors.settings.walletPass.foregroundColor": undefined;
  "visitors.settings.walletPass.labelColor": undefined;
  "visitors.settings.walletPass.additionalInputsInfo": undefined;
  "visitors.settings.walletPass.previewHeaderGoogle": undefined;
  "visitors.settings.walletPass.previewHeaderApple": undefined;
  "visitors.settings.walletPass.preview.logoText": undefined;
  "visitors.settings.walletPass.preview.appointmentTitle": undefined;
  "visitors.settings.walletPass.preview.location": undefined;
  "visitors.settings.walletPass.preview.host": undefined;
  "visitors.settings.walletPass.preview.company": undefined;
  "visitors.settings.walletPass.preview.date": undefined;
  "visitors.settings.walletPass.preview.time": undefined;
  "visitors.type.requireAssistance": undefined;
  "visitors.type.vip": undefined;
  "visitors.walkIn.addVisitors": undefined;
  "visitors.walkIn.additionalDetails": undefined;
  "visitors.walkIn.emptyVisitors": undefined;
  "visitors.walkIn.newWalkIn": undefined;
  "visitors.walkIn.nextStep": undefined;
  "visitors.walkIn.selectHost": undefined;
  "visitors.walkIn.step": { step: string | number };
  "visitors.walkIn.titleTemplate": { name: string | number };
  "visitors.walkIn.visitDuration": undefined;
  "visitors.walkIn.walkInCancelBody": undefined;
  "visitors.widgets.accessReception": undefined;
  "visitors.widgets.backToTop": undefined;
  "visitors.widgets.noAccess": undefined;
  "visitors.widgets.noDataCurrentlyAvailable": { title: string | number };
  "visitors.widgets.preBookedVisitors": undefined;
  "visitors.widgets.statsTooltipPropertyManager": undefined;
  "visitors.widgets.statsTooltipWorkplaceManager": undefined;
  "welcome.inviteSettings.activityHistory": undefined;
  "welcome.inviteStatuses.approvePendingApproval": undefined;
  "welcome.inviteStatuses.invitationResent": undefined;
  "welcome.inviteStatuses.invitationSent": undefined;
  "welcome.inviteStatuses.unknownStatus": undefined;
  "welcome.inviteStatuses.userRegistered": undefined;
  "welcome.inviteStatuses.userUnsubscribed": undefined;
  "developer.apiEndpoint": undefined;
  "developer.confirmCreateButtonText": undefined;
  "developer.confirmCreateMessage": undefined;
  "developer.confirmCreateTitle": undefined;
  "developer.confirmRevokeMessage": undefined;
  "developer.confirmRevokeRevoking": undefined;
  "developer.confirmRevokeTitle": undefined;
  "developer.copyToClipboard": undefined;
  "developer.copiedToClipboard": undefined;
  "developer.createRefreshToken": undefined;
  "developer.createdAt": undefined;
  "developer.deviceName": undefined;
  "developer.heading": undefined;
  "developer.id": undefined;
  "developer.maximumAllowed": undefined;
  "developer.noRefreshTokens": undefined;
  "developer.newTokenModalTitle": undefined;
  "developer.newTokenModalMessage": undefined;
  "developer.newTokenModalCloseButton": undefined;
  "developer.refreshTokensTab": undefined;
  "developer.revoke": undefined;
}

export const languages: Languages = {
  "cs": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/cs/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/cs/server-messages.json"),
  },
  "de": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/de/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/de/server-messages.json"),
  },
  "en": {
    "equiem-one-ui": enApplication,
    "server-messages": enServer,
  },
  "en-GB": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/en_GB/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/en_GB/server-messages.json"),
  },
  "en-US": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/en_US/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/en_US/server-messages.json"),
  },
  "es": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/es/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/es/server-messages.json"),
  },
  "fr": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/fr/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/fr/server-messages.json"),
  },
  "hu": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/hu/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/hu/server-messages.json"),
  },
  "ja": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/ja/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/ja/server-messages.json"),
  },
  "nl": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/nl/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/nl/server-messages.json"),
  },
  "pl": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/pl/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/pl/server-messages.json"),
  },
  "pt-PT": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/pt_PT/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/pt_PT/server-messages.json"),
  },
  "ro": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/ro/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/ro/server-messages.json"),
  },
  "sk": {
    "equiem-one-ui": async () => import("./../../../localisation/src/locales/sk/equiem-one-ui.json"),
    "server-messages": async () => import("./../../../localisation/src/locales/sk/server-messages.json"),
  },
};

export type TFunction = TFunctionGeneric<ApplicationMessages>;

export const useTranslation = (ns?: string, options?: UseTranslationOptions) => useTranslationGeneric<ApplicationMessages>(ns ?? "equiem-one-ui", options);

export type WithTranslationProps = WithTranslationPropsGeneric<ApplicationMessages>;

export const withTranslation = () => withTranslationGeneric<ApplicationMessages>();

export type TransProps = TransPropsGeneric<ApplicationMessages>;

export const Trans: FC<TransProps> = makeTransComponent<ApplicationMessages>();

export {
  useServerMessageTranslation,
  useApolloErrorTranslation,
  useErrorTranslation,
  formatters,
  type i18n,
} from "@equiem/localisation";

/** WARNING: Do not use in production code! For use in Cypress tests only. */
export const standaloneTestT = standaloneTestTInternal as TFunction;

/** WARNING: Do not use in production code! For use in Cypress tests only. */
export const getCustomStandaloneTestT = (options: StandaloneTestTOptions) =>
  getCustomStandaloneTestTInternal<TFunction>(options);
